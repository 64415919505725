import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import { MutationRefreshPlansArgs } from '@coverright/data-access/types/medicare';

export function useRefreshPlans(options?: MutationHookOptions<{refreshPlans: boolean}, MutationRefreshPlansArgs>) {
  return useMutation<{refreshPlans: boolean}, MutationRefreshPlansArgs>(gql(mutation), {
    ...options,
  })
}

const mutation = `
mutation ($clientId: UUID!) {
  refreshPlans(clientId: $clientId)
}
`;
