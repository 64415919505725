import React, { useRef } from 'react';
import { colors } from '@coverright/ui/themes';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AdminContext, ProfileContext } from '@coverright/shared/contexts';
import {
  useMyCompareYearToYear,
  useCompareYearToYearByClientId,
  useMedicarePlan,
  usePdpPlan,
  PdpPlanWithTiers,
  PlanWithTiers,
  useSetCompareYearToYearLocked,
  useSaveCompareYearToYear,
} from '@coverright/data-access/medicare';
import {
  CompareYearToYearOutput,
  PlanYear,
} from '@coverright/data-access/types/medicare';
import * as _ from 'lodash';
import {
  getCompanyLogo,
  getMedigapCompanyLogo,
  GetNextPlansYear,
  GetPlansYear,
  toCurrency,
} from '@coverright/utils';
import { useMedigapPlansByBid } from '@coverright/data-access/medigap';
import { CRPreloader } from '@coverright/ui/shared';
import { environment } from '../../../environments/environment';
import moment from 'moment';
import {
  useGetMyMedigapEnrollments,
  useMigrateY2YByClientId,
  useSendY2YEmail,
} from '@coverright/data-access/enrollment';
import RefreshIcon from '@mui/icons-material/Refresh';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { KeycloakContext } from '@coverright/shared/keycloak';
import html2canvas from 'html2canvas';
import { useSnackbar } from 'notistack';
import { AgentCard } from '../../components/agent-card';
import { ScheduleACallButton } from '@coverright/features';

const YearToYearCompare = () => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));
  const adminContext = React.useContext(AdminContext);
  const { isAdmin, initialized } = React.useContext(KeycloakContext);
  const { firstName } = React.useContext(ProfileContext);
  const [getY2YByClientId] = useCompareYearToYearByClientId();
  const [getY2Y] = useMyCompareYearToYear();
  const [locked, setLocked] = React.useState(true);
  const [totalSavings, setTotalSavings] = React.useState(0);
  const [compareObject, setCompareObject] =
    React.useState<CompareYearToYearOutput>();
  const [getPlan] = useMedicarePlan();
  const [getPdpPlan] = usePdpPlan();
  const [getMyMedigapEnrollment, myMedigapEnrollmentData] =
    useGetMyMedigapEnrollments();
  const [migrateY2YByClientId, migrateY2YByClientIdData] =
    useMigrateY2YByClientId();
  const [maPlans, setMaPlans] = React.useState<PlanWithTiers[]>([]);
  const [pdpPlans, setPdpPlans] = React.useState<PdpPlanWithTiers[]>([]);
  const [saveY2Y] = useSaveCompareYearToYear();

  const clientId = adminContext?.clientId;

  const [setCompareYearToYearlocked] = useSetCompareYearToYearLocked();

  const ref = useRef<any>(null);
  const [sendingEmail, setSendingEmail] = React.useState(false);
  const [sendEmail] = useSendY2YEmail();

  const GetImageBlob = React.useCallback(
    async (callback: (data: any) => void) => {
      if (ref.current) {
        const css = '* {box-shadow: none!important;}  .hide {display: none;};',
          style = document.createElement('style');
        style.id = 'remove-shadows';

        style.appendChild(document.createTextNode(css));
        document.body.prepend(style);
        window.scrollTo(0, 0);
        return html2canvas(ref.current, { useCORS: true }).then((canvas) =>
          canvas.toBlob(callback)
        );
      }
      return undefined;
    },
    [ref]
  );

  const onSendEmailClick = React.useCallback(() => {
    setSendingEmail(true);
    GetImageBlob((image: any) => {
      if (image && adminContext?.clientId) {
        sendEmail({ variables: { image, clientId: adminContext?.clientId } })
          .then(() => {
            enqueueSnackbar('Email sent!', { variant: 'info' });
          })
          .catch((e) => {
            enqueueSnackbar('Email sending error', { variant: 'error' });
          })
          .finally(() => {
            setSendingEmail(false);
            document.querySelector('#remove-shadows')?.remove();
          });
      }
    });
  }, [adminContext?.clientId]);

  const maPlanBids = React.useMemo(() => {
    const res = [
      compareObject?.currentMaPlanId,
      compareObject?.suggestedMaPlanId,
    ];
    return res.map((bidId, index) => ({
      zip: compareObject?.zip || '',
      countyName: compareObject?.county || '',
      quoteId: adminContext?.clientView?.quoteId,
      bidId,
      planYear: index
        ? GetNextPlansYear()
        : compareObject?.currentYear || GetPlansYear(),
    }));
  }, [compareObject]);

  React.useEffect(() => {
    const proc = async () => {
      const plans: any = [];
      for (const plan of maPlanBids) {
        if (plan.bidId) {
          plans.push(
            await getPlan({
              variables: {
                ...plan,
                bidId: plan.bidId,
                fullYear: true,
                planYear: plan.planYear,
              },
            }).then((data) => data.data?.plan)
          );
        } else {
          plans.push(null);
        }
      }
      setMaPlans(plans);
    };
    if (maPlanBids.length > 0 && compareObject?.clientId) {
      proc();
    }
  }, [maPlanBids, compareObject]);

  const maDiff = React.useMemo(() => {
    if (compareObject?.maIsHidden) {
      return 0;
    }
    if (_.compact(maPlans).length > 1) {
      return (
        monthToYear(maPlans[0].monthlyCost) -
        monthToYear(maPlans[1].monthlyCost)
      );
    }
    return 0;
  }, [maPlans, compareObject]);

  const pdpPlanBids = React.useMemo(() => {
    const res = [
      compareObject?.currentPdpPlanId,
      compareObject?.suggestedPdpPlanId,
    ];
    return res.map((bidId, index) => ({
      zip: compareObject?.zip || '',
      countyName: compareObject?.county || '',
      quoteId: adminContext?.clientView?.quoteId,
      bidId,
      planYear: index
        ? GetNextPlansYear()
        : compareObject?.currentYear || GetPlansYear(),
    }));
  }, [compareObject]);

  React.useEffect(() => {
    const proc = async () => {
      if (pdpPlanBids[0].bidId) {
        const plans: any = [];
        for (const plan of pdpPlanBids) {
          if (plan.bidId) {
            plans.push(
              await getPdpPlan({
                variables: {
                  ...plan,
                  bidId: plan.bidId,
                  fullYear: true,
                  planYear: plan.planYear,
                  clientId: compareObject?.clientId,
                },
              }).then((data) => data.data?.PdpPlan)
            );
          } else {
            plans.push(null);
          }
        }
        setPdpPlans(plans);
      }
    };
    if (pdpPlanBids.length > 0 && compareObject?.clientId) {
      proc();
    }
  }, [pdpPlanBids, compareObject]);

  const pdpDiff = React.useMemo(() => {
    if (compareObject?.pdpIsHidden) {
      return 0;
    }
    if (_.compact(pdpPlans).length > 1) {
      return (
        monthToYear(pdpPlans[0].monthlyCost) -
        monthToYear(pdpPlans[1].monthlyCost)
      );
    }
    return 0;
  }, [pdpPlans, compareObject]);

  const mgPlanKeys = React.useMemo(
    () =>
      _.compact([
        compareObject?.currentMgPlanKey,
        compareObject?.suggestedMgPlanKey,
      ]),
    [compareObject]
  );

  const { plans: mgPlans } = useMedigapPlansByBid(mgPlanKeys);

  const mgEnrollmentDate = React.useMemo(() => {
    return (
      adminContext?.clientView.createdAt ||
      myMedigapEnrollmentData.data?.getMyMedigapEnrollments?.createdAt
    );
  }, [adminContext, myMedigapEnrollmentData]);

  const mgDiff = React.useMemo(() => {
    if (compareObject?.mgIsHidden) {
      return 0;
    }
    if (_.compact(mgPlans).length > 1) {
      return (
        monthToYear(mgPlans[0].monthlyPremium) -
        monthToYear(mgPlans[1].monthlyPremium)
      );
    }
    return 0;
  }, [mgPlans, compareObject]);

  React.useEffect(() => {
    setTotalSavings(mgDiff + pdpDiff + maDiff);
  }, [mgDiff, pdpDiff, maDiff]);

  React.useEffect(() => {
    if (initialized) {
      if (isAdmin) {
        if (adminContext?.clientId) {
          getY2YByClientId({
            variables: { clientId: adminContext.clientId },
          }).then((res) =>
            setCompareObject(res.data?.compareYearToYearByClientId || undefined)
          );
        }
      } else {
        getY2Y().then((res) =>
          setCompareObject(res.data?.myCompareYearToYear || undefined)
        );
        getMyMedigapEnrollment();
      }
    }
  }, [initialized, isAdmin, adminContext?.clientId]);

  const name = React.useMemo(() => {
    return adminContext
      ? adminContext?.clientView?.firstName
        ? ' ' + adminContext?.clientView?.firstName
        : ''
      : firstName || '';
  }, [adminContext, firstName]);

  const loading = React.useMemo(() => {
    return (
      (_.compact(_.map(maPlanBids, 'bidId')).length > 0 && !maPlans.length) ||
      (mgPlanKeys.length > 0 && !mgPlans.length) ||
      (_.compact(_.map(pdpPlanBids, 'bidId')).length > 0 && !pdpPlans.length)
    );
  }, [maPlans, maPlanBids, mgPlans, mgPlanKeys, pdpPlanBids, pdpPlans]);

  React.useEffect(() => {
    if (compareObject) {
      setLocked(compareObject.isLocked);
    }
  }, [compareObject?.isLocked]);

  const handlelockedChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (compareObject) {
      setLocked(event.target.checked);
      await setCompareYearToYearlocked({
        variables: {
          id: compareObject.id,
          isLocked: event.target.checked,
        },
      });
    }
  };

  const toggleHiddenFlag = React.useCallback(
    (flag: 'MA' | 'MG' | 'PDP') => {
      if (adminContext?.clientId && compareObject) {
        saveY2Y({
          variables: {
            input: {
              ...{
                ..._.omit(compareObject!, 'isLocked'),
                createdAt: undefined,
                updatedAt: undefined,
              },
              county: compareObject.county as string,
              zip: compareObject.zip as string,
              currentYear: compareObject.currentYear as PlanYear,
              maIsHidden:
                flag === 'MA'
                  ? !compareObject.maIsHidden
                  : !!compareObject.maIsHidden,
              mgIsHidden:
                flag === 'MG'
                  ? !compareObject.mgIsHidden
                  : !!compareObject.mgIsHidden,
              pdpIsHidden:
                flag === 'PDP'
                  ? !compareObject.pdpIsHidden
                  : !!compareObject.pdpIsHidden,
            },
          },
        });
        setCompareObject({
          ...compareObject,
          maIsHidden:
            flag === 'MA'
              ? !compareObject.maIsHidden
              : !!compareObject.maIsHidden,
          mgIsHidden:
            flag === 'MG'
              ? !compareObject.mgIsHidden
              : !!compareObject.mgIsHidden,
          pdpIsHidden:
            flag === 'PDP'
              ? !compareObject.pdpIsHidden
              : !!compareObject.pdpIsHidden,
        });
      }
    },
    [compareObject, saveY2Y, adminContext?.clientId]
  );

  return (
    <Box sx={{ width: 1, maxWidth: 1100, margin: 'auto' }}>
      <Dialog open={phone}>
        <img src={'/assets/img/rotate-phone.png'} />
      </Dialog>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {!migrateY2YByClientIdData.loading && (
          <CircularProgress className={'mr-15'} size={15} />
        )}
        {!adminContext && (
          <>
            <Button
              disabled={migrateY2YByClientIdData.loading || !clientId}
              sx={{ color: colors.text.primary, mr: 2 }}
              startIcon={<RefreshIcon />}
              onClick={() =>
                migrateY2YByClientId({
                  variables: { clientId: clientId! },
                }).then(() => document.location.reload())
              }
            >
              Refresh comparison
            </Button>
            <Button
              disabled={sendingEmail || loading}
              startIcon={<MailOutlineIcon />}
              sx={{ color: colors.text.primary }}
              onClick={onSendEmailClick}
            >
              Send email
            </Button>
            <Divider
              orientation={'vertical'}
              sx={{ mx: 2, borderRightWidth: 'medium' }}
            />
            <FormControlLabel
              control={
                <Switch checked={locked} onChange={handlelockedChange} />
              }
              className={'fs-14 bold'}
              label={
                <Typography className={'bold fs-14'}>Lock offer</Typography>
              }
            />
          </>
        )}
      </Box>
      <Box sx={{ width: 1, display: 'flex', gap: '17px', margin: 'auto' }}>
        <Box
          sx={{ width: 1, maxWidth: 800, '*': { letterSpacing: '-0.04em' } }}
        >
          <Box
            sx={{
              width: '100%',
              boxSizing: 'border-box',
              height: { sm: 145 },
              p: { sm: '20px' },
              mb: '14px',
              backgroundSize: 'auto',
              backgroundImage: 'url("/assets/img/annual-review.png")',
            }}
          >
            <Typography className={'bold fs-40 lh-40 mb-16'}>
              Annual Review <img src={'/assets/img/note.png'} />
            </Typography>
            <Typography className={'medium fs-16 lh-20'}>
              Please find below the results of your annual plan review. If{' '}
              {!phone && <br />}you have any questions, feel free to reach out
              to us.
            </Typography>
          </Box>

          <Card
            ref={ref}
            sx={{ p: '30px 20px', borderTop: '7px solid #5ECB9E' }}
          >
            <div className={'hide'}>
              <Typography className={'fs-32 mb-30'}>Hi {name},</Typography>
              <Typography className={'thin fs-22 lh-27 mb-30'}>
                We have completed your annual plan review and believe there may
                potentially be a better plan for you in 2023...
              </Typography>
            </div>

            {_.compact(_.map(maPlanBids, 'bidId')).length > 0 &&
              !!compareObject?.maIsHidden &&
              adminContext && (
                <Box
                  component={'span'}
                  onClick={() => {
                    toggleHiddenFlag('MA');
                  }}
                  className={'fs-14 pointer red underline hide'}
                >
                  Unhide MA section
                </Box>
              )}
            {_.compact(_.map(maPlanBids, 'bidId')).length > 0 &&
              !compareObject?.maIsHidden && (
                <div data-test={'y2y_wrapper'} className={'mb-45'}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      mb: 2,
                      alignItems: 'center',
                    }}
                  >
                    <Typography className={'bold fs-24 lh-30'}>
                      Medicare Advantage
                    </Typography>
                    {adminContext && (
                      <Box
                        component={'a'}
                        target={'_blank'}
                        href={`${environment.medicareAdvantageUrl}plans/${compareObject?.zip}/${compareObject?.county}?clientId=${adminContext?.clientId}`}
                        className={'fs-14 pointer dark-green hide'}
                      >
                        Change suggested plan
                      </Box>
                    )}
                    {adminContext && (
                      <Box
                        component={'span'}
                        onClick={() => toggleHiddenFlag('MA')}
                        className={'fs-14 pointer red underline hide'}
                      >
                        Hide section
                      </Box>
                    )}
                  </Box>
                  {!maPlans.length && <CRPreloader fill />}
                  {maPlans.length > 0 && (
                    <div>
                      {!maPlans[0] && <NoPlanMessage />}
                      {!!maPlans[0] && (
                        <Box sx={{ display: 'flex', gap: '7px' }}>
                          <Box
                            data-test={'y2y_current_plan'}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              flex: 1,
                            }}
                          >
                            <Header title={'Current Plan'} />
                            <PlanCard
                              planName={maPlans[0].planName || ''}
                              onDetailsClick={() =>
                                window.open(
                                  `${environment.medicareAdvantageUrl}plan/${compareObject?.zip}/${compareObject?.county}/${maPlans[0].bidId}` +
                                    (adminContext?.clientId
                                      ? `?planYear=${maPlans[0].planYear}&clientId=${adminContext.clientId}`
                                      : '')
                                )
                              }
                              logo={getCompanyLogo(
                                maPlans[0].parentOrgName,
                                maPlans[0].orgName
                              )}
                              companyName={maPlans[0].parentOrgName || ''}
                              price={
                                toCurrency(
                                  monthToYear(maPlans[0].monthlyCost),
                                  2,
                                  2
                                ) || ''
                              }
                              dateString={<Box sx={{ height: '18px' }} />}
                              details={
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      mb: '15px',
                                    }}
                                  >
                                    <Typography className={'fs-16 lh-20 thin'}>
                                      Estimated annual drug costs:
                                    </Typography>
                                    <Typography
                                      align={'right'}
                                      className={'fs-22 lh-22 thin'}
                                    >
                                      {
                                        maPlans[0].drugCostMonthlyBreakdown
                                          ?.totalClientCost
                                      }
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      mb: '15px',
                                    }}
                                  >
                                    <Typography className={'fs-16 lh-20 thin'}>
                                      Monthly plan premium:
                                    </Typography>
                                    <Typography
                                      align={'right'}
                                      className={'fs-22 lh-22 thin'}
                                    >
                                      {toCurrency(
                                        parseFloat(
                                          maPlans[0].monthlyPremium
                                            ?.replace('$', '')
                                            .replace(',', '') || '0'
                                        ) * 12,
                                        2,
                                        2
                                      ) || ''}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flex: 1,
                                      justifyContent: 'space-between',
                                      mb: '15px',
                                    }}
                                  >
                                    <div>
                                      <Typography
                                        className={'fs-16 lh-20 thin'}
                                      >
                                        Drug deductible:
                                      </Typography>
                                      <Typography
                                        sx={{ color: 'rgba(123, 143, 149, 1)' }}
                                        className={'fs-12 lh-15 thin'}
                                      >
                                        {maPlans[0].drugDetails
                                          ?.mrxAltNoDedTier || ''}
                                      </Typography>
                                    </div>
                                    <Typography
                                      align={'right'}
                                      className={'fs-22 lh-22 thin'}
                                    >
                                      {maPlans[0].drugDetails?.mrxAltDedAmount}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      mb: '15px',
                                    }}
                                  >
                                    <div>
                                      <Typography
                                        className={'fs-16 lh-20 bold'}
                                      >
                                        Total estimated annual costs:
                                      </Typography>
                                      <Typography
                                        sx={{ color: 'rgba(2, 144, 148, 1)' }}
                                        className={
                                          'fs-12 lh-15 thin mt-5 ml-12'
                                        }
                                      >
                                        + Estimated annual drug costs
                                      </Typography>
                                      <Typography
                                        sx={{ color: 'rgba(2, 144, 148, 1)' }}
                                        className={
                                          'fs-12 lh-15 thin mt-5 ml-12'
                                        }
                                      >
                                        + Plan premium x 12 months
                                      </Typography>
                                      <Typography
                                        sx={{ color: 'rgba(2, 144, 148, 1)' }}
                                        className={
                                          'fs-12 lh-15 thin mt-5 ml-12'
                                        }
                                      >
                                        + Deductible (if applicable)
                                      </Typography>
                                    </div>
                                    <Typography
                                      align={'right'}
                                      className={'fs-22 lh-22 thin'}
                                    >
                                      {toCurrency(
                                        parseFloat(
                                          maPlans[0].monthlyCost
                                            ?.replace('$', '')
                                            .replace(',', '') || '0'
                                        ) * 12,
                                        2,
                                        2
                                      )}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      mb: '15px',
                                    }}
                                  >
                                    <Typography className={'fs-16 lh-20 thin'}>
                                      Drugs covered:
                                    </Typography>
                                    <Typography
                                      align={'right'}
                                      className={'fs-22 lh-22 thin'}
                                    >
                                      {
                                        maPlans[0].drugsCoverage.filter(
                                          (d) => d.coverage
                                        ).length
                                      }{' '}
                                      of {maPlans[0].drugsCoverage.length}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      mb: '15px',
                                    }}
                                  >
                                    <Typography className={'fs-16 lh-20 thin'}>
                                      In-network pharmacies:
                                    </Typography>
                                    <Typography
                                      align={'right'}
                                      className={'fs-22 lh-22 thin'}
                                    >
                                      {maPlans[0].quoteDrugsTiers?.pharmacyName
                                        ? '1'
                                        : '0'}{' '}
                                      of{' '}
                                      {maPlans[0].quoteDrugsTiers?.pharmacyName
                                        ? '1'
                                        : '0'}
                                    </Typography>
                                  </Box>
                                </Box>
                              }
                            />
                          </Box>
                          <Box sx={{ pt: '125px' }}>
                            <img src={'/assets/img/compare-arrow.svg'} />
                          </Box>
                          {!maPlans[1] && <NoPlanMessage />}
                          {maPlans[1] && (
                            <Box
                              data-test={'y2y_suggested_plan'}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                              }}
                            >
                              <Header title={'Suggested Plan'} />
                              <PlanCard
                                planName={maPlans[1].planName || ''}
                                onDetailsClick={() =>
                                  window.open(
                                    `${environment.medicareAdvantageUrl}plan/${compareObject?.zip}/${compareObject?.county}/${maPlans[1].bidId}` +
                                      (adminContext?.clientId
                                        ? `?planYear=${maPlans[1].planYear}&clientId=${adminContext.clientId}`
                                        : '')
                                  )
                                }
                                logo={getCompanyLogo(
                                  maPlans[1].parentOrgName,
                                  maPlans[1].orgName
                                )}
                                companyName={maPlans[1].parentOrgName || ''}
                                price={
                                  toCurrency(
                                    monthToYear(maPlans[1].monthlyCost),
                                    2,
                                    2
                                  ) || ''
                                }
                                dateString={
                                  <Typography
                                    sx={{ color: '#778E95' }}
                                    className={'fs-11 bold'}
                                  >
                                    Effective start date: January 1, 2023
                                  </Typography>
                                }
                                extra={
                                  <Typography
                                    className={'fs-14 thin lh-14 no-wrap'}
                                  >
                                    Annual Cost Savings:{' '}
                                    <Box
                                      component={'span'}
                                      sx={{ color: 'rgba(29, 156, 128, 1)' }}
                                      className={'fs-20 medium lh-22 mr-5'}
                                    >
                                      {toCurrency(maDiff)}
                                    </Box>
                                  </Typography>
                                }
                                details={
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flex: 1,
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        mb: '15px',
                                      }}
                                    >
                                      <Typography
                                        className={'fs-16 lh-20 thin'}
                                      >
                                        Estimated annual drug costs:
                                      </Typography>
                                      <Typography
                                        align={'right'}
                                        className={'fs-22 lh-22 thin'}
                                      >
                                        {
                                          maPlans[1].drugCostMonthlyBreakdown
                                            ?.totalClientCost
                                        }
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        mb: '15px',
                                      }}
                                    >
                                      <Typography
                                        className={'fs-16 lh-20 thin'}
                                      >
                                        Monthly plan premium:
                                      </Typography>
                                      <Typography
                                        align={'right'}
                                        className={'fs-22 lh-22 thin'}
                                      >
                                        {toCurrency(
                                          parseFloat(
                                            maPlans[1].monthlyPremium
                                              ?.replace('$', '')
                                              .replace(',', '') || '0'
                                          ) * 12,
                                          2,
                                          2
                                        ) || ''}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flex: 1,
                                        justifyContent: 'space-between',
                                        mb: '15px',
                                      }}
                                    >
                                      <div>
                                        <Typography
                                          className={'fs-16 lh-20 thin'}
                                        >
                                          Drug deductible:
                                        </Typography>
                                        <Typography
                                          sx={{
                                            color: 'rgba(123, 143, 149, 1)',
                                          }}
                                          className={'fs-12 lh-15 thin'}
                                        >
                                          {maPlans[1].drugDetails
                                            ?.mrxAltNoDedTier || ''}
                                        </Typography>
                                        <div></div>
                                      </div>
                                      <Typography
                                        align={'right'}
                                        className={'fs-22 lh-22 thin'}
                                      >
                                        {
                                          maPlans[1].drugDetails
                                            ?.mrxAltDedAmount
                                        }
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        mb: '15px',
                                      }}
                                    >
                                      <div>
                                        <Typography
                                          className={'fs-16 lh-20 bold'}
                                        >
                                          Total estimated annual costs:
                                        </Typography>
                                        <Typography
                                          sx={{ color: 'rgba(2, 144, 148, 1)' }}
                                          className={
                                            'fs-12 lh-15 thin mt-5 ml-12'
                                          }
                                        >
                                          + Estimated annual drug costs
                                        </Typography>
                                        <Typography
                                          sx={{ color: 'rgba(2, 144, 148, 1)' }}
                                          className={
                                            'fs-12 lh-15 thin mt-5 ml-12'
                                          }
                                        >
                                          + Plan premium x 12 months
                                        </Typography>
                                        <Typography
                                          sx={{ color: 'rgba(2, 144, 148, 1)' }}
                                          className={
                                            'fs-12 lh-15 thin mt-5 ml-12'
                                          }
                                        >
                                          + Deductible (if applicable)
                                        </Typography>
                                      </div>
                                      <Typography
                                        align={'right'}
                                        className={'fs-22 lh-22 thin'}
                                      >
                                        {toCurrency(
                                          parseFloat(
                                            maPlans[1].monthlyCost
                                              ?.replace('$', '')
                                              .replace(',', '') || '0'
                                          ) * 12,
                                          2,
                                          2
                                        )}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        mb: '15px',
                                      }}
                                    >
                                      <Typography
                                        className={'fs-16 lh-20 thin'}
                                      >
                                        Drugs covered:
                                      </Typography>
                                      <Typography
                                        align={'right'}
                                        className={'fs-22 lh-22 thin'}
                                      >
                                        {
                                          maPlans[1].drugsCoverage.filter(
                                            (d) => d.coverage
                                          ).length
                                        }{' '}
                                        of {maPlans[1].drugsCoverage.length}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        mb: '15px',
                                      }}
                                    >
                                      <Typography
                                        className={'fs-16 lh-20 thin'}
                                      >
                                        In-network pharmacies:
                                      </Typography>
                                      <Typography
                                        align={'right'}
                                        className={'fs-22 lh-22 thin'}
                                      >
                                        {maPlans[1].quoteDrugsTiers
                                          ?.pharmacyName
                                          ? '1'
                                          : '0'}{' '}
                                        of{' '}
                                        {maPlans[1].quoteDrugsTiers
                                          ?.pharmacyName
                                          ? '1'
                                          : '0'}
                                      </Typography>
                                    </Box>
                                  </Box>
                                }
                              />
                            </Box>
                          )}
                        </Box>
                      )}
                    </div>
                  )}
                </div>
              )}

            {mgPlanKeys.length > 0 &&
              !!compareObject?.mgIsHidden &&
              adminContext && (
                <Box
                  component={'span'}
                  onClick={() => {
                    toggleHiddenFlag('MG');
                  }}
                  className={'fs-14 pointer red underline hide'}
                >
                  Unhide MG section
                </Box>
              )}
            {mgPlanKeys.length > 0 && !compareObject?.mgIsHidden && (
              <div data-test={'y2y_wrapper'} className={'mb-45'}>
                <Box
                  sx={{ display: 'flex', gap: 2, mb: 2, alignItems: 'center' }}
                >
                  <Typography className={'bold fs-24 lh-30'}>
                    Medicare Supplement
                  </Typography>
                  {adminContext && (
                    <Box
                      component={'a'}
                      target={'_blank'}
                      href={`${environment.medigapUrl}plans/${compareObject?.zip}/${compareObject?.county}?clientId=${adminContext?.clientId}&effectiveDate=2023-01-01&y2y`}
                      className={'fs-14 pointer dark-green hide'}
                    >
                      Change suggested plan
                    </Box>
                  )}
                  {adminContext && (
                    <Box
                      component={'span'}
                      onClick={() => toggleHiddenFlag('MG')}
                      className={'fs-14 pointer red underline hide'}
                    >
                      Hide section
                    </Box>
                  )}
                </Box>

                {!mgPlans.length && <CRPreloader fill />}

                {mgPlans.length > 0 && (
                  <div>
                    {!mgPlans[0] && <NoPlanMessage />}
                    {!!mgPlans[0] && (
                      <Box sx={{ display: 'flex', gap: '7px' }}>
                        <Box
                          data-test={'y2y_current_plan'}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                          }}
                        >
                          <Header title={'Current Plan'} />
                          <PlanCard
                            planName={
                              'Medicare Supplement - Plan ' +
                              mgPlans[0].planName
                            }
                            onDetailsClick={() =>
                              window.open(
                                `${environment.medigapUrl}plans/${
                                  compareObject?.zip
                                }/${
                                  compareObject?.county
                                }?effectiveDate=${moment(
                                  mgEnrollmentDate
                                ).format('YYYY-MM-DD')}` +
                                  (adminContext?.clientId
                                    ? `&clientId=${adminContext.clientId}`
                                    : '') +
                                  `#${mgPlans[0].key}`
                              )
                            }
                            logo={getMedigapCompanyLogo(
                              mgPlans[0].parentOrgName,
                              mgPlans[0].orgName
                            )}
                            companyName={mgPlans[0].title || ''}
                            price={
                              toCurrency(
                                monthToYear(mgPlans[0].monthlyPremium),
                                2,
                                2
                              ) || ''
                            }
                            dateString={
                              <Typography
                                sx={{ color: '#778E95' }}
                                className={'fs-11 bold'}
                              >
                                Effective start date:{' '}
                                {moment(mgEnrollmentDate).format('LL')}
                              </Typography>
                            }
                          />
                        </Box>
                        <Box sx={{ pt: '125px' }}>
                          <img src={'/assets/img/compare-arrow.svg'} />
                        </Box>
                        {!mgPlans[1] && <NoPlanMessage />}
                        {mgPlans[1] && (
                          <Box
                            data-test={'y2y_suggested_plan'}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              flex: 1,
                            }}
                          >
                            <Header title={'Suggested Plan'} />
                            <PlanCard
                              planName={
                                'Medicare Supplement - Plan ' +
                                mgPlans[1].planName
                              }
                              onDetailsClick={() =>
                                window.open(
                                  `${environment.medigapUrl}plans/${compareObject?.zip}/${compareObject?.county}?effectiveDate=2023-01-01` +
                                    (adminContext?.clientId
                                      ? `&clientId=${adminContext.clientId}`
                                      : '') +
                                    `#${mgPlans[1].key}`
                                )
                              }
                              logo={getMedigapCompanyLogo(
                                mgPlans[1].parentOrgName,
                                mgPlans[1].orgName
                              )}
                              companyName={mgPlans[1].title || ''}
                              price={
                                toCurrency(
                                  monthToYear(mgPlans[1].monthlyPremium),
                                  2,
                                  2
                                ) || ''
                              }
                              dateString={
                                <Typography
                                  sx={{ color: '#778E95' }}
                                  className={'fs-11 bold'}
                                >
                                  Effective start date: January 1, 2023
                                </Typography>
                              }
                              extra={
                                <Typography
                                  className={'fs-14 thin lh-14 no-wrap'}
                                >
                                  Annual Cost Savings:{' '}
                                  <Box
                                    component={'span'}
                                    sx={{ color: 'rgba(29, 156, 128, 1)' }}
                                    className={'fs-20 medium lh-22 mr-5'}
                                  >
                                    {toCurrency(mgDiff)}
                                  </Box>
                                </Typography>
                              }
                            />
                          </Box>
                        )}
                      </Box>
                    )}
                  </div>
                )}
              </div>
            )}

            {_.compact(_.map(pdpPlanBids, 'bidId')).length > 0 &&
              !!compareObject?.pdpIsHidden &&
              adminContext && (
                <Box
                  component={'span'}
                  onClick={() => {
                    toggleHiddenFlag('PDP');
                  }}
                  className={'fs-14 pointer red underline hide'}
                >
                  Unhide PDP section
                </Box>
              )}
            {_.compact(_.map(pdpPlanBids, 'bidId')).length > 0 &&
              !compareObject?.pdpIsHidden && (
                <div
                  data-test={'y2y_wrapper'}
                  id={'pdp_comparison'}
                  className={'mb-45'}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      mb: 2,
                      alignItems: 'center',
                    }}
                  >
                    <Typography className={'bold fs-24 lh-30'}>
                      Prescription Drug Plan
                    </Typography>
                    {adminContext && (
                      <Box
                        component={'a'}
                        target={'_blank'}
                        href={`${environment.pdpUrl}plans/${compareObject?.zip}/${compareObject?.county}?clientId=${adminContext?.clientId}`}
                        className={'fs-14 pointer dark-green hide'}
                      >
                        Change suggested plan
                      </Box>
                    )}
                    {adminContext && (
                      <Box
                        component={'span'}
                        onClick={() => toggleHiddenFlag('PDP')}
                        className={'fs-14 pointer red underline hide'}
                      >
                        Hide section
                      </Box>
                    )}
                  </Box>

                  {!pdpPlans.length && <CRPreloader fill />}

                  {pdpPlans.length > 0 && (
                    <div>
                      {!pdpPlans[0] && <NoPlanMessage />}
                      {!!pdpPlans[0] && (
                        <Box sx={{ display: 'flex', gap: '7px' }}>
                          <Box
                            data-test={'y2y_current_plan'}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              flex: 1,
                            }}
                          >
                            <Header title={'Current Plan'} />
                            <PlanCard
                              planName={pdpPlans[0].planName || ''}
                              onDetailsClick={() =>
                                window.open(
                                  `${environment.pdpUrl}plans/${compareObject?.zip}/${compareObject?.county}` +
                                    (adminContext?.clientId
                                      ? `?planYear=${pdpPlans[0].planYear}&clientId=${adminContext.clientId}`
                                      : '') +
                                    `#${pdpPlans[0].bidId}`
                                )
                              }
                              logo={getCompanyLogo(
                                pdpPlans[0].parentOrgName,
                                pdpPlans[0].orgName
                              )}
                              companyName={pdpPlans[0].parentOrgName || ''}
                              price={
                                toCurrency(
                                  monthToYear(pdpPlans[0].monthlyCost),
                                  2,
                                  2
                                ) || ''
                              }
                              dateString={<Box sx={{ height: '18px' }} />}
                              details={
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      mb: '15px',
                                    }}
                                  >
                                    <Typography className={'fs-16 lh-20 thin'}>
                                      Estimated annual drug costs:
                                    </Typography>
                                    <Typography
                                      align={'right'}
                                      className={'fs-22 lh-22 thin'}
                                    >
                                      {
                                        pdpPlans[0].drugCostMonthlyBreakdown
                                          ?.totalClientCost
                                      }
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      mb: '15px',
                                    }}
                                  >
                                    <Typography className={'fs-16 lh-20 thin'}>
                                      Monthly plan premium:
                                    </Typography>
                                    <Typography
                                      align={'right'}
                                      className={'fs-22 lh-22 thin'}
                                    >
                                      {toCurrency(
                                        parseFloat(
                                          pdpPlans[0].monthlyPremium
                                            ?.replace('$', '')
                                            .replace(',', '') || '0'
                                        ) * 12,
                                        2,
                                        2
                                      ) || ''}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flex: 1,
                                      justifyContent: 'space-between',
                                      mb: '15px',
                                    }}
                                  >
                                    <div>
                                      <Typography
                                        className={'fs-16 lh-20 thin'}
                                      >
                                        Drug deductible:
                                      </Typography>
                                      <Typography
                                        sx={{ color: 'rgba(123, 143, 149, 1)' }}
                                        className={'fs-12 lh-15 thin'}
                                      >
                                        {pdpPlans[0].drugDetails
                                          ?.mrxAltNoDedTier || ''}
                                      </Typography>
                                    </div>
                                    <Typography
                                      align={'right'}
                                      className={'fs-22 lh-22 thin'}
                                    >
                                      {pdpPlans[0].drugDetails?.mrxAltDedAmount}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      mb: '15px',
                                    }}
                                  >
                                    <div>
                                      <Typography
                                        className={'fs-16 lh-20 bold'}
                                      >
                                        Total estimated annual costs:
                                      </Typography>
                                      <Typography
                                        sx={{ color: 'rgba(2, 144, 148, 1)' }}
                                        className={
                                          'fs-12 lh-15 thin mt-5 ml-12'
                                        }
                                      >
                                        + Estimated annual drug costs
                                      </Typography>
                                      <Typography
                                        sx={{ color: 'rgba(2, 144, 148, 1)' }}
                                        className={
                                          'fs-12 lh-15 thin mt-5 ml-12'
                                        }
                                      >
                                        + Plan premium x 12 months
                                      </Typography>
                                      <Typography
                                        sx={{ color: 'rgba(2, 144, 148, 1)' }}
                                        className={
                                          'fs-12 lh-15 thin mt-5 ml-12'
                                        }
                                      >
                                        + Deductible (if applicable)
                                      </Typography>
                                    </div>
                                    <Typography
                                      align={'right'}
                                      className={'fs-22 lh-22 thin'}
                                    >
                                      {toCurrency(
                                        parseFloat(
                                          pdpPlans[0].monthlyCost
                                            ?.replace('$', '')
                                            .replace(',', '') || '0'
                                        ) * 12,
                                        2,
                                        2
                                      )}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      mb: '15px',
                                    }}
                                  >
                                    <Typography className={'fs-16 lh-20 thin'}>
                                      Drugs covered:
                                    </Typography>
                                    <Typography
                                      align={'right'}
                                      className={'fs-22 lh-22 thin'}
                                    >
                                      {
                                        pdpPlans[0].drugsCoverage.filter(
                                          (d) => d.coverage
                                        ).length
                                      }{' '}
                                      of {pdpPlans[0].drugsCoverage.length}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      mb: '15px',
                                    }}
                                  >
                                    <Typography className={'fs-16 lh-20 thin'}>
                                      In-network pharmacies:
                                    </Typography>
                                    <Typography
                                      align={'right'}
                                      className={'fs-22 lh-22 thin'}
                                    >
                                      {pdpPlans[0].quoteDrugsTiers?.pharmacyName
                                        ? '1'
                                        : '0'}{' '}
                                      of{' '}
                                      {pdpPlans[0].quoteDrugsTiers?.pharmacyName
                                        ? '1'
                                        : '0'}
                                    </Typography>
                                  </Box>
                                </Box>
                              }
                            />
                          </Box>
                          <Box sx={{ pt: '125px' }}>
                            <img src={'/assets/img/compare-arrow.svg'} />
                          </Box>
                          {!pdpPlans[1] && <NoPlanMessage />}
                          {pdpPlans[1] && (
                            <Box
                              data-test={'y2y_suggested_plan'}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                              }}
                            >
                              <Header title={'Suggested Plan'} />
                              <PlanCard
                                planName={pdpPlans[1].planName || ''}
                                onDetailsClick={() =>
                                  window.open(
                                    `${environment.pdpUrl}plans/${compareObject?.zip}/${compareObject?.county}` +
                                      (adminContext?.clientId
                                        ? `?planYear=${pdpPlans[1].planYear}&clientId=${adminContext.clientId}`
                                        : '') +
                                      `#${pdpPlans[1].bidId}`
                                  )
                                }
                                logo={getCompanyLogo(
                                  pdpPlans[1].parentOrgName,
                                  pdpPlans[1].orgName
                                )}
                                companyName={pdpPlans[1].parentOrgName || ''}
                                price={
                                  toCurrency(
                                    monthToYear(pdpPlans[1].monthlyCost),
                                    2,
                                    2
                                  ) || ''
                                }
                                dateString={
                                  <Typography
                                    sx={{ color: '#778E95' }}
                                    className={'fs-11 bold'}
                                  >
                                    Effective start date: January 1,{' '}
                                    {new Date().getFullYear() + 1}
                                  </Typography>
                                }
                                extra={
                                  <Typography
                                    className={'fs-14 thin lh-14 no-wrap'}
                                  >
                                    Annual Cost Savings:{' '}
                                    <Box
                                      component={'span'}
                                      sx={{ color: 'rgba(29, 156, 128, 1)' }}
                                      className={'fs-20 medium lh-22 mr-5'}
                                    >
                                      {toCurrency(pdpDiff)}
                                    </Box>
                                  </Typography>
                                }
                                details={
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flex: 1,
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        mb: '15px',
                                      }}
                                    >
                                      <Typography
                                        className={'fs-16 lh-20 thin'}
                                      >
                                        Estimated annual drug costs:
                                      </Typography>
                                      <Typography
                                        align={'right'}
                                        className={'fs-22 lh-22 thin'}
                                      >
                                        {
                                          pdpPlans[1].drugCostMonthlyBreakdown
                                            ?.totalClientCost
                                        }
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        mb: '15px',
                                      }}
                                    >
                                      <Typography
                                        className={'fs-16 lh-20 thin'}
                                      >
                                        Monthly plan premium:
                                      </Typography>
                                      <Typography
                                        align={'right'}
                                        className={'fs-22 lh-22 thin'}
                                      >
                                        {toCurrency(
                                          parseFloat(
                                            pdpPlans[1].monthlyPremium
                                              ?.replace('$', '')
                                              .replace(',', '') || '0'
                                          ) * 12,
                                          2,
                                          2
                                        ) || ''}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flex: 1,
                                        justifyContent: 'space-between',
                                        mb: '15px',
                                      }}
                                    >
                                      <div>
                                        <Typography
                                          className={'fs-16 lh-20 thin'}
                                        >
                                          Drug deductible:
                                        </Typography>
                                        <Typography
                                          sx={{
                                            color: 'rgba(123, 143, 149, 1)',
                                          }}
                                          className={'fs-12 lh-15 thin'}
                                        >
                                          {pdpPlans[1].drugDetails
                                            ?.mrxAltNoDedTier || ''}
                                        </Typography>
                                      </div>
                                      <Typography
                                        align={'right'}
                                        className={'fs-22 lh-22 thin'}
                                      >
                                        {
                                          pdpPlans[1].drugDetails
                                            ?.mrxAltDedAmount
                                        }
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        mb: '15px',
                                      }}
                                    >
                                      <div>
                                        <Typography
                                          className={'fs-16 lh-20 bold'}
                                        >
                                          Total estimated annual costs:
                                        </Typography>
                                        <Typography
                                          sx={{ color: 'rgba(2, 144, 148, 1)' }}
                                          className={
                                            'fs-12 lh-15 thin mt-5 ml-12'
                                          }
                                        >
                                          + Estimated annual drug costs
                                        </Typography>
                                        <Typography
                                          sx={{ color: 'rgba(2, 144, 148, 1)' }}
                                          className={
                                            'fs-12 lh-15 thin mt-5 ml-12'
                                          }
                                        >
                                          + Plan premium x 12 months
                                        </Typography>
                                        <Typography
                                          sx={{ color: 'rgba(2, 144, 148, 1)' }}
                                          className={
                                            'fs-12 lh-15 thin mt-5 ml-12'
                                          }
                                        >
                                          + Deductible (if applicable)
                                        </Typography>
                                      </div>
                                      <Typography
                                        align={'right'}
                                        className={'fs-22 lh-22 thin'}
                                      >
                                        {toCurrency(
                                          parseFloat(
                                            pdpPlans[1].monthlyCost
                                              ?.replace('$', '')
                                              .replace(',', '') || '0'
                                          ) * 12,
                                          2,
                                          2
                                        )}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        mb: '15px',
                                      }}
                                    >
                                      <Typography
                                        className={'fs-16 lh-20 thin'}
                                      >
                                        Drugs covered:
                                      </Typography>
                                      <Typography
                                        align={'right'}
                                        className={'fs-22 lh-22 thin'}
                                      >
                                        {
                                          pdpPlans[1].drugsCoverage.filter(
                                            (d) => d.coverage
                                          ).length
                                        }{' '}
                                        of {pdpPlans[1].drugsCoverage.length}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        mb: '15px',
                                      }}
                                    >
                                      <Typography
                                        className={'fs-16 lh-20 thin'}
                                      >
                                        In-network pharmacies:
                                      </Typography>
                                      <Typography
                                        align={'right'}
                                        className={'fs-22 lh-22 thin'}
                                      >
                                        {pdpPlans[1].quoteDrugsTiers
                                          ?.pharmacyName
                                          ? '1'
                                          : '0'}{' '}
                                        of{' '}
                                        {pdpPlans[1].quoteDrugsTiers
                                          ?.pharmacyName
                                          ? '1'
                                          : '0'}
                                      </Typography>
                                    </Box>
                                  </Box>
                                }
                              />
                            </Box>
                          )}
                        </Box>
                      )}
                    </div>
                  )}
                </div>
              )}

            <Box sx={{ height: '37px' }} />

            <Card
              sx={{
                p: '24px',
                width: 1,
                margin: 'auto',
                borderRadius: '10px',
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
                boxSizing: 'border-box',
              }}
            >
              <Typography align={'center'} className={'fs-24 lh-40 thin'}>
                Total estimated savings:{' '}
                <Box component={'span'} sx={{ color: 'rgba(29, 156, 128, 1)' }}>
                  <b className={'fs-45 lh-55'}>{toCurrency(totalSavings)}</b>{' '}
                  per year
                </Box>{' '}
                <img className={'ml-10'} src={'/assets/img/party.png'} />
              </Typography>
              <Box sx={{ margin: 'auto', mt: '20px' }}>
                <Button
                  size={'large'}
                  variant={'contained'}
                  onClick={() => /*navigate(PageRoutes.speakToAdvisor)*/ {
                    throw new Error('not implemented');
                  }}
                  sx={{ width: 425 }}
                >
                  Continue
                </Button>
              </Box>
            </Card>
          </Card>
        </Box>
        <Box sx={{ maxWidth: 282, display: { xs: 'none', lg: 'block' } }}>
          <Box sx={{ position: 'sticky', top: 90 }}>
            {compareObject && (
              <AgentCard
                onCreateAccountClick={() => {
                  document.location.href =
                    environment.profileUrl +
                    `info?redirectUrl=` +
                    encodeURIComponent(document.location.href);
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default YearToYearCompare;

interface PlanCardProps {
  details?: JSX.Element;
  planName: string;
  logo: string;
  companyName: string;
  dateString: JSX.Element;
  extra?: JSX.Element;
  price: string;
  onDetailsClick: () => void;
}

const PlanCard = (props: PlanCardProps) => {
  return (
    <Card
      sx={{
        p: '12px 18px 14px 12px',
        mt: 2,
        borderRadius: '10px',
        flex: 1,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{
            backgroundPosition: 'left',
            height: 36,
            width: 122,
            minWidth: 122,
            marginTop: 0,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundImage: `url("${props.logo}")`,
          }}
        />
        <div>
          <Typography align={'right'} className={'fs-28 lh-35 thin'}>
            {props.price}
          </Typography>
          <Typography align={'right'} className={'fs-14 lh-17 thin'}>
            per year
          </Typography>
        </div>
      </Box>
      <div className={'mb-20'}>
        <Typography className={'fs-20 lh-25 thin'}>
          {props.companyName}
        </Typography>
        <Typography
          sx={{ mt: '2px', mb: '5px' }}
          className={'fs-14 lh-17 bold'}
        >
          {props.planName}
        </Typography>
        {props.dateString}
      </div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: { md: 'center' },
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <div>{props.extra}</div>
        <Button
          className={'thin lh-14'}
          onClick={props.onDetailsClick}
          variant={'contained'}
          sx={{
            color: 'white',
            width: 120,
            height: 28,
            backgroundColor: '#1E95A0',
            borderRadius: '4px',
          }}
        >
          Plan Details
        </Button>
      </Box>
      {props.details && (
        <>
          <Divider sx={{ borderColor: '#7B8F95', mt: '25px', mb: '11px' }} />
          <Typography className={'fs-20 lh-27 mb-20 bold'}>Details</Typography>
          {props.details}
        </>
      )}
    </Card>
  );
};

const Header = ({ title }: { title: string }) => (
  <Typography
    sx={{ background: '#C8E8EB', py: '4px', borderRadius: '4px' }}
    className={'fs-18 lh-22'}
    align={'center'}
  >
    {title}
  </Typography>
);

const NoPlanMessage = () => {
  return (
    <Box
      data-test={'y2y_suggested_plan'}
      sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          flex: 1,
        }}
      >
        <Card sx={{ p: '20px 30px', maxWidth: 700 }}>
          <Stack spacing={'15px'}>
            <Typography className={'fs-20 bold lh-27'}>
              Your Licenced Medicare Advisor is working on your customized plan
              options
            </Typography>
            <Typography className={'fs-16 lh-20'}>
              Your advisor will upload your personalized Medicare plan once
              completed to this page. You will receive multiple Medicare options
              to consider. After reviewing your plan your advisor will be
              available to answer any questions and help you enroll.
            </Typography>
            <Typography className={'fs-16 lh-20'}>
              Speak with a Medicare Advisor now.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flex: 1,
              }}
            >
              <ScheduleACallButton
                className={'gtm-button-event w-150'}
                data-gtm-button-name={'BOOK_A_CALL_BUTTON'}
              >
                Book a call
              </ScheduleACallButton>
            </Box>
          </Stack>
        </Card>
      </Box>
    </Box>
  );
};

function monthToYear(monthPrice?: string | null): number {
  const num = parseFloat(monthPrice?.replace('$', '').replace(',', '') || '0');
  return num * 12;
}
