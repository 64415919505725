import { Box, Theme, Typography } from '@mui/material';
import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Radio } from '@coverright/ui/inputs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radio: {
      border: '1px solid white',
      justifyContent: 'center',
      height: (props: StyledRadioProps) => props.height ? props.height : 188,
      flex: 1,
      width: '100%',
      maxWidth: 181,
      padding: '15px 22px 15px 15px!important',
      margin: '0!important',
      position: 'relative',
    },
    mark: {
      position: 'absolute!important' as any,
      top: 0,
      left: 0
    }
  })
);

/* eslint-disable-next-line */
export interface StyledRadioProps {
  imgUrl?: string,
  imageClassName?: string,
  label: string | JSX.Element,
  checked: boolean,
  onClick: () => void,
  height?: number
}

export function StyledRadio(props: StyledRadioProps) {
  const classes = useStyles(props);
  return (
    <Radio checked={props.checked}
             /*controlClassName={classes.radio}*/
             className={classes.mark}
             onClick={props.onClick} label={(
      <Box p={'27px 0 0'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <Box sx={{mb: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '91px'}}>
          {props.imgUrl && <img src={props.imgUrl} className={props.imageClassName} />}
        </Box>
        <Box textAlign={'center'}>
          <Typography className={'medium fs-18 lh-22'}>{props.label}</Typography>
        </Box>
      </Box>
    )} />

  );
}

export default StyledRadio;
