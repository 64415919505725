import {
  getAgeByBirthdate,
  getCId,
  getMedigapQuoteId,
  getStoredUser,
  setMedigapQuoteId,
} from '@coverright/utils';
import { environment } from '../../environments/environment';
import React from 'react';
import { AppActionTypes, AppContext } from '@coverright/shared/contexts';
import { MedigapQuoteContext, QuoteContext } from '@coverright/shared/contexts';
import { useCreateMedigapQuoteFromMedicareQuote } from '@coverright/data-access/medigap';
import { Gender } from '@coverright/data-access/types/medigap';
import { KeycloakContext } from '@coverright/shared/keycloak';

export const useGoToMedigap = () => {
  const {id, save} = React.useContext(QuoteContext);
  const [{state: {quizSelected}}, dispatch] = React.useContext(AppContext);
  const {save: saveMGQuote} = React.useContext(MedigapQuoteContext)
  const {getHash} = React.useContext(KeycloakContext);

  const onCreateCompleted = (data: any) => {
    setMedigapQuoteId(data.createMedigapQuoteFromMedicareQuote.mgQuoteId)

    let age = getAgeByBirthdate(getStoredUser()?.birthDate);

    if (!age || age >= 63 && age < 65) {
      age = 65;
    }

    saveMGQuote({
      variables: {
        input: {
          id: data.createMedigapQuoteFromMedicareQuote.mgQuoteId,
          cId: getCId(),
          medigapFilterState: {
            zip: getStoredUser()?.zip as string,
            county: getStoredUser()?.countyName as string,
            age,
            gender: getStoredUser()?.gender as Gender,
            tobacco: getStoredUser()?.tobacco === 'true',
          }
        }
      }
    })
      .then((d: any) => {
        dispatch({
          type: AppActionTypes.SAVE_STATE,
          payload: {
            stateKey: 'marketplaceRedirect',
            value: ''
          }
        });
        setTimeout(() => {
          document.location.href = `${environment.medigapUrl}plans/${getStoredUser()?.zip}/${getStoredUser()?.countyName}?medigapQuote=${getMedigapQuoteId()}${getStoredUser()?.firstName ? '&name=' + getStoredUser()?.firstName : ''}&birthdate=${getStoredUser()?.birthDate}` + (quizSelected === 4 ? '&hd=true' : '') + getHash()
        }, 300)
      })
  }


  const [createMGQuote] = useCreateMedigapQuoteFromMedicareQuote({
    onCompleted: onCreateCompleted
  });

  const goToMG = React.useCallback(() => {
    if (getMedigapQuoteId()) {
      dispatch({
        type: AppActionTypes.SAVE_STATE,
        payload: {
          stateKey: 'marketplaceRedirect',
          value: ''
        }
      });
      setTimeout(() => {
        document.location.href = `${environment.medigapUrl}plans/${getStoredUser()?.zip}/${getStoredUser()?.countyName}?medigapQuote=${getMedigapQuoteId()}${getStoredUser()?.firstName ? '&name=' + getStoredUser()?.firstName : ''}&birthdate=${getStoredUser()?.birthDate}` + (quizSelected === 4 ? '&hd=true' : '') + getHash();
      }, 300)
    } else {
      if (id) {
        createMGQuote({ variables: { medicareQuoteId: id } });
      } else {
        let age = getAgeByBirthdate(getStoredUser()?.birthDate);

        if (!age || age >= 63 && age < 65) {
          age = 65;
        }

        saveMGQuote({
          variables: {
            input: {
              cId: getCId(),
              medigapFilterState: {
                zip: getStoredUser()?.zip as string,
                county: getStoredUser()?.countyName as string,
                age,
                gender: getStoredUser()?.gender as Gender,
                tobacco: getStoredUser()?.tobacco === 'true',
              }
            }
          }
        })
          .then((d: any) => {
            setMedigapQuoteId(d.data?.saveMedigapQuote.mgQuoteId)
            dispatch({
              type: AppActionTypes.SAVE_STATE,
              payload: {
                stateKey: 'marketplaceRedirect',
                value: ''
              }
            });
            setTimeout(() => {
              document.location.href = `${environment.medigapUrl}plans/${getStoredUser()?.zip}/${getStoredUser()?.countyName}?medigapQuote=${d.data?.saveMedigapQuote.mgQuoteId}${getStoredUser()?.firstName ? '&name=' + getStoredUser()?.firstName : ''}&birthdate=${getStoredUser()?.birthDate}` + (quizSelected === 4 ? '&hd=true' : '') + getHash()
            }, 300)
          })
      }
    }
  }, [id, saveMGQuote]);

  return goToMG
}
