import { getCId, getStoredUser, getUId } from '@coverright/utils';

export function identifyHotjar(additionalAttributes: { [key: string]: string | boolean } = {}) {
  (window as any).hj = (window as any).hj || function(...args: any){((window as any).hj.q=(window as any).hj.q || []).push(args)};
  const identifyData = {
    ...additionalAttributes,
    email: getStoredUser()?.email,
    c_id: getCId(),
    uid: getUId(),
  };
  (window as any).hj('identify', getCId() || null, identifyData);
}
