import * as React from 'react';
import { logEvent } from './logEvent';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  CalendlyInviteeDocument,
  CalendlyInviteeQuery,
  CalendlyInviteeQueryVariables
} from '@coverright/data-access/types/enrollment';

export function useTrackAppointment(enrollmentApolloClient: ApolloClient<NormalizedCacheObject>) {
  React.useEffect(() => {
    function isCalendlyEvent(e: MessageEvent<any>) {
      return e.data.event && e.data.event.indexOf('calendly') === 0;
    }

    const listener = async (message: MessageEvent<any>) => {
      if (isCalendlyEvent(message)) {
        if (message.data.event === 'calendly.event_scheduled') {
          await logEvent('Appointment is successfully booked', await getCalendlyInvitee(enrollmentApolloClient, message.data.payload.event.uri, message.data.payload.invitee.uri));
        }
      }
    };
    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }, [])

}

const getCalendlyInvitee = (enrollmentApolloClient: ApolloClient<NormalizedCacheObject>, eventUrl: string, inviteeUrl: string) => {
  return enrollmentApolloClient.query<CalendlyInviteeQuery, CalendlyInviteeQueryVariables>({
    query: CalendlyInviteeDocument,
    variables: {eventUrl, inviteeUrl}
  }).then(r => r.data?.calendlyInvitee)
}
