import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import { enrollmentClient } from '@coverright/data-access/apollo-clients';
import { Maybe, MedigapEnrollmentOutput } from '@coverright/data-access/types/enrollment';

export function useGetMyMedigapEnrollments(options?: LazyQueryHookOptions<{getMyMedigapEnrollments: Maybe<MedigapEnrollmentOutput>}>) {
  return useLazyQuery<{getMyMedigapEnrollments: Maybe<MedigapEnrollmentOutput>}>(
    gql(query),
    {
      ...options,
      client: enrollmentClient
    }
  );
}

const query = `
{
  getMyMedigapEnrollments {
    accessibleFormatPreference
    age
    approvedAt
    birthDate
    clientId
    county
    createdAt
    effectiveDate
    email
    firstName
    gender
    id
    lastName
    medigapQuoteId
    naic
    phoneNumber
    planKey
    planName
    started
    status
    tobacco
    zip
  }
}
`;
