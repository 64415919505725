import { getStoredUser, setStoredUser } from '@coverright/utils';
import moment from 'moment';
import { Gender } from '@coverright/data-access/types/medigap';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { getZipCountyFips } from '@coverright/data-access/medicare';

/*
 Fills setStoredUser from parameters from url and trying to calculate missed county and state if not provided.
 If can't calculate and if fallbackRoute is provided, then forward user to this route
@client - main backend client
 */
export async function fillStoredUserFromUrlParams(client: ApolloClient<NormalizedCacheObject>, fallbackRoute?: string) {
  const urlParams = new URLSearchParams(window.location.search);

  urlParams.has('quote') && addParam('quoteId', urlParams.get('quote'));
  urlParams.has('medigapQuote') &&
    addParam('medigapQuoteId', urlParams.get('medigapQuote'));
  urlParams.has('source') && addParam('profileSource', urlParams.get('source'));

  const firstName = urlParams.get('firstName') || urlParams.get('first_name');
  firstName && addParam('firstName', firstName);

  const lastName = urlParams.get('lastName') || urlParams.get('last_name');
  lastName && addParam('lastName', lastName);

  if (urlParams.has('dob') && moment(urlParams.get('dob')).isValid()) {
    addParam('birthDate', moment(urlParams.get('dob')).format('YYYY-MM-DD'));
  }

  urlParams.has('email') && addParam('email', urlParams.get('email'));
  urlParams.has('phone') && addParam('phoneNumber', urlParams.get('phone'));
  urlParams.has('gender') &&
    addParam(
      'gender',
      urlParams.get('gender') === 'female' ? Gender.F : Gender.M
    );
  urlParams.has('tobacco') && addParam('tobacco', urlParams.get('tobacco'));

  if (urlParams.has('target')) {
    sessionStorage.setItem('target', urlParams.get('target') as string);
  }

  if (urlParams.has('utm_source')) {
    sessionStorage.setItem('utm_source', urlParams.get('utm_source') as string);
  }

  if (urlParams.has('utm_term') && urlParams.get('utm_term') !== 'general') {
    sessionStorage.setItem('skipConversion', 'true');
  }

  const shouldLoadLocationData = urlParams.has('zip') &&
    (!getStoredUser()?.zip ||
      !getStoredUser()?.countyName ||
      getStoredUser()?.zip !== urlParams.get('zip'))

  if (shouldLoadLocationData) {
    const zip = urlParams.get('zip')!;

    if (!urlParams.has('county')) {
      const res = await getZipCountyFips(client, zip);

      if (res.data?.plans_zip_county_fips.length === 1) {
        setStoredUser({
          ...getStoredUser(),
          countyName: res.data?.plans_zip_county_fips[0].countyname,
          state: res.data?.plans_zip_county_fips[0].state,
          zip,
        });
      } else {
        console.info('There is more then one county in this zip.');
        setStoredUser({
          ...getStoredUser(),
          zip,
        });
        if (fallbackRoute) {
          document.location.href = fallbackRoute;
        }
      }
    } else {
      setStoredUser({
        ...getStoredUser(),
        countyName: urlParams.get('county')!,
        zip,
        state: urlParams.get('state') || undefined,
      });
    }
  }
}

const addParam = (name: string, value: any) => {
  setStoredUser({
    ...getStoredUser(),
    [name]: value,
  });
};
