import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  MutationCreateMedigapQuoteFromMedicareQuoteArgs,
  SaveMedigapQuoteOutput
} from '@coverright/data-access/types/medigap';

export function useCreateMedigapQuoteFromMedicareQuote(options?: MutationHookOptions<{createMedigapQuoteFromMedicareQuote: SaveMedigapQuoteOutput}, MutationCreateMedigapQuoteFromMedicareQuoteArgs>) {
  return useMutation<{createMedigapQuoteFromMedicareQuote: SaveMedigapQuoteOutput}, MutationCreateMedigapQuoteFromMedicareQuoteArgs>(
    gql(request),
    {
      ...options,
    }
  );
}


const request = `
mutation ($medicareQuoteId: UUID!) {
  createMedigapQuoteFromMedicareQuote(medicareQuoteId: $medicareQuoteId) {
    clientId
    mgQuoteId
  }
}
`;
