import React from "react";
import { Box, IconButton, Typography } from '@mui/material';
import { AdminContext } from '@coverright/shared/contexts';
import { useSnackbar } from 'notistack';

const AdminNotification = () => {
  const adminContext = React.useContext(AdminContext);
  const fullName = `${adminContext?.clientView.firstName} ${adminContext?.clientView.lastName}`.trim();
  const { enqueueSnackbar } = useSnackbar();

  if (adminContext == null) {
    return null
  }

  return <Box sx={{
    height: '50px',
    padding: '0 18px',
    color: 'white',
    background: '#FB0A0A',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }}>
    <Box display={'flex'} alignItems={'center'}>
      <Typography className={'fs-18 bold white'} variant={'h4'}>{`${fullName}'s offer`}</Typography>
      <Typography className={`fs-18 bold white underline ml-20 pointer`} onClick={() => {
        navigator.clipboard.writeText(document.location.origin + document.location.pathname + '?magic-link=true')
        enqueueSnackbar('Copied!', {variant: "info"});
      }} variant={'h4'}>Copy link</Typography>
    </Box>
    <IconButton onClick={adminContext.clear}><img src={'/assets/img/white-close.svg'} /></IconButton>
  </Box>
};

export default AdminNotification;
