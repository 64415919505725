import * as React from 'react';
import { KeycloakContext, KeycloakRoles } from '@coverright/shared/keycloak';
import Keycloak from 'keycloak-js';
import { useEffect } from 'react';
import { ABTestConfig, useAbTests } from './hooks/use-ab-tests';

interface UserTestConfig {
  compare_year_to_year: boolean,
  flexpa: boolean,
}

interface IUserTestContext {
  isTester: boolean,
  config: UserTestConfig,
  abTestConfig: ABTestConfig,
}

const initialConfig: UserTestConfig = {
  compare_year_to_year: false,
  flexpa: false
}

export const UserTestContext = React.createContext<IUserTestContext>({
  isTester: false,
  config: initialConfig,
  abTestConfig: {}
});

export const getIsTester = (keycloak: Keycloak) => keycloak.hasRealmRole(KeycloakRoles.ROLE_ALFA);

export function UserTestContextProvider(props: React.PropsWithChildren<any>) {
  const [config, setConfig] = React.useState<UserTestConfig>(initialConfig);
  const [isTester, setIsTester] = React.useState<boolean>(false);
  const {initialized, keycloak} = React.useContext(KeycloakContext);
  const abTestConfig = useAbTests()

  React.useEffect(() => {
    if (initialized && keycloak?.authenticated) {
      setIsTester(getIsTester(keycloak));
      init(getIsTester(keycloak))
    }
  }, [initialized, keycloak?.authenticated])

  useEffect(() => {
    init(false)
  }, []);

  const init = async (tester: boolean) => {
    try {
      const state = await fetch(process.env.NX_USER_TEST_CONFIG_URL as string).then(r => r.json())
      setConfig(tester ? state.alfa : state.default)
    } catch (e) {
      console.error('Error:', e);
    }
  }

  return <UserTestContext.Provider value={{config, isTester, abTestConfig}}>
    {props.children}
  </UserTestContext.Provider>
}

export const withUserTestContext = (WrappedComponent: any) => (props: any) => {
  return (
    <UserTestContextProvider>
      <WrappedComponent {...props} />
    </UserTestContextProvider>
  )
}
