import { MutationHookOptions, useMutation } from '@apollo/client';
import {gql} from "@apollo/client";
import { enrollmentClient } from '@coverright/data-access/apollo-clients';
import { MutationMigrateY2YByClientIdArgs } from '@coverright/data-access/types/enrollment';

export function useMigrateY2YByClientId(options?: MutationHookOptions<{migrateY2YByClientId: boolean}, MutationMigrateY2YByClientIdArgs>) {
  return useMutation<{migrateY2YByClientId: boolean}, MutationMigrateY2YByClientIdArgs>(gql(query), {
    client: enrollmentClient,
    fetchPolicy: "no-cache",
    ...options
  });
}

const query = `
mutation($clientId: UUID!) {
  migrateY2YByClientId(clientId: $clientId)
}
`
