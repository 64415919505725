import { Outlet } from 'react-router-dom';
import React from 'react';
import { KeycloakContext } from '@coverright/shared/keycloak';

export function PrivateRoute({ fallback }: { fallback?: React.JSX.Element }) {
  const { keycloak, authenticated } = React.useContext(KeycloakContext);

  if (!authenticated) {
    if (fallback) {
      return fallback;
    }
    if (keycloak) {
      keycloak.login();
    }
  }

  return <Outlet />;
}
