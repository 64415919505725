import { ElevationScroll } from '@coverright/utils';
import { Box, Toolbar, AppBar as MuiAppBar, IconButton } from '@mui/material';
import { ContactsSection } from '@coverright/legacy';
import React from 'react';

export function AppBar() {
  return <>
    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
      <ElevationScroll>
        <MuiAppBar sx={{ top: 0 }}>
          <Toolbar style={{ minHeight: '48px', padding: '3px 20px' }}>
            <Box display={'flex'} flex={1} alignItems={'center'} justifyContent={'space-between'}>
              <Box display={'flex'} alignItems={'center'} gap={1}>
                <img src={'/assets/img/Logo.svg'}
                     width={120}
                     height={31}
                     className={'pointer'}
                     onClick={() => document.location.href = document.location.origin} />
              </Box>
              <ContactsSection />
            </Box>
          </Toolbar>
        </MuiAppBar>
      </ElevationScroll>
    </Box>
    <Box sx={{ display: { md: 'none' } }} display={'flex'} flex={1} justifyContent={'space-between'}
         alignItems={'center'} px={'10px'}>
      <img src={'/assets/img/Logo.svg'} width={91} />
      <IconButton sx={{
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 49,
        height: 49,
        borderRadius: '50%',
      }} href="tel:8889697667">
        <img src={'/assets/img/phone.svg'} />
      </IconButton>
    </Box>
  </>;
}
