import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import { CompareOfferHistoryOutput, QueryCompareOfferHistoryArgs } from '@coverright/data-access/types/enrollment';
import { enrollmentClient } from '@coverright/data-access/apollo-clients';

export function useCompareOfferHistory(options?: LazyQueryHookOptions<{compareOfferHistory: Array<CompareOfferHistoryOutput>}, QueryCompareOfferHistoryArgs>) {
  return useLazyQuery<{compareOfferHistory: Array<CompareOfferHistoryOutput>}, QueryCompareOfferHistoryArgs>(gql(query), {
    client: enrollmentClient,
    fetchPolicy: "no-cache",
    ...options
  });
}

const query = `
query ($clientId: UUID!) {
  compareOfferHistory(clientId: $clientId) {
    clientId
    createdAt
    id
    screenshotLink
  }
}
`
