import { useSaveCompareOfferHistoryItem } from '@coverright/data-access/enrollment';

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Drawer,
  MenuItem,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import React, { useRef } from 'react';
import {
  AdminContext,
  CompareOfferContext,
  ProfileContext,
  withCompareOfferContext,
} from '@coverright/shared/contexts';
import { Link } from 'react-router-dom';
import { ComparablePlanType } from '@coverright/data-access/types/medicare';
import {
  PlanWithTiers,
  useMedicarePlansByBid,
  usePdpPlansByBid,
  useRefreshPlans,
} from '@coverright/data-access/medicare';
import {
  getCompanyLogo,
  getMedigapCompanyLogo,
  getNumbersFromString,
  getQuoteId,
  toCurrency,
} from '@coverright/utils';
import {
  addDetailsToMGPlanList,
  MedigapPlanOutputWithDetails,
  useMedigapPlansByBid,
} from '@coverright/data-access/medigap';
import { SxProps } from '@mui/material';
import { KeycloakContext } from '@coverright/shared/keycloak';
import { colors } from '@coverright/ui/themes';
import * as _ from 'lodash';
import {
  Benefit,
  DoctorCoverageList,
  DrugCoverageList,
} from '@coverright/ui/marketplaces';
import { V3Preloader } from '@coverright/ui/shared';
import html2canvas from 'html2canvas';
import { environment } from '../../../environments/environment';
import { PageRoutes } from '../../config/page-routes';
import { SelectInput } from '@coverright/ui/inputs';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { CompareOfferHistoryList } from './compare-offer-history-list';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { ScheduleACallButton } from '@coverright/features';

const styles: SxProps = {
  position: 'relative',
  overflowY: { xs: 'auto', md: 'unset' },
  '& table': {
    background: 'white',
    borderSpacing: '5px',
    '& ul': {
      marginBlockStart: '3px',
      marginBlockEnd: '10px',
      paddingInlineStart: '16px',
    },
    '& td': {
      fontSize: 13,
      color: colors.text.primary,
      whiteSpace: 'break-spaces',
    },
    '& thead td': {
      fontSize: 14,
      whiteSpace: 'nowrap',
      '&.medigap': {
        background: '#0f738b',
      },
      '&.medicare': {
        background: '#062b34',
      },
    },
    '& tbody td': {
      padding: '1px 5px',
      '&:first-child': {
        position: 'sticky',
        left: 0,
        zIndex: 1,
        background: 'white',
        '&:not(.bg-white)': {
          background: '#c8e8eb',
          fontWeight: '700',
        },
      },
      '&:not(:first-child)': {
        '&:not(.header)': {
          verticalAlign: 'top',
        },
        '&.header': {
          verticalAlign: 'bottom',
        },
        borderBottom: '1px solid #d9d9d9',
        minWidth: 250,
      },
    },
  },
};

function CompareOffer() {
  const adminContext = React.useContext(AdminContext);
  const { enqueueSnackbar } = useSnackbar();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { plans, clientId, changePdpPrimary, refetch, loading } =
    React.useContext(CompareOfferContext);
  const { keycloak, initialized } = React.useContext(KeycloakContext);
  const { firstName, lastName, countyName, state } =
    React.useContext(ProfileContext);
  const [maComparablePlans, setMaComparablePlans] = React.useState<any[]>([]);
  const [mgComparablePlans, setMgComparablePlans] = React.useState<any[]>([]);
  const [mgBids, setMgBids] = React.useState<string[]>([]);
  const [screenshotProcess, setScreenshotProcess] = React.useState(false);
  const [saveProcess, setSaveProcess] = React.useState(false);
  const [refreshProcess, setRefreshProcess] = React.useState(false);
  const [pdpComparePlans, setPdpComparePlans] = React.useState<any[]>();
  const [refreshPlans] = useRefreshPlans({ variables: { clientId } });
  const {
    plans: maPlans,
    loading: maLoading,
    called: maCalled,
  } = useMedicarePlansByBid(maComparablePlans);
  const {
    plans: mgPlansInput,
    loading: mgLoading,
    called: mgCalled,
  } = useMedigapPlansByBid(mgBids);
  const { plans: pdpPlans } = usePdpPlansByBid(pdpComparePlans, clientId);
  const [left, setLeft] = React.useState(0);
  const ref = useRef<any>(null);
  const [saveInHistory] = useSaveCompareOfferHistoryItem({
    onCompleted: () =>
      enqueueSnackbar('Screenshot saved', { variant: 'success' }),
  });

  React.useEffect(() => {
    if (initialized && !keycloak.authenticated) {
      keycloak.login();
    }
  }, [initialized]);

  useScrollPosition(({ currPos }) => {
    setLeft(Math.abs(currPos.x));
  }, []);

  const pdpPlan = React.useMemo(() => {
    if (pdpPlans.length) {
      if (
        pdpPlans.length > 1 &&
        pdpPlans?.some(
          (p) => p.bidId === pdpComparePlans?.find((cp) => cp.pdpPrimary)?.bidId
        )
      ) {
        return pdpPlans?.find(
          (p) => p.bidId === pdpComparePlans?.find((cp) => cp.pdpPrimary)?.bidId
        );
      }
      return pdpPlans[0];
    }
    return undefined;
  }, [pdpPlans, pdpComparePlans]);

  React.useEffect(() => {
    setMaComparablePlans(
      plans
        .filter((p) => p.type === ComparablePlanType.Ma)
        .map((plan) => ({
          bidId: plan.bidId,
          zip: plan.zip,
          countyName: plan.county,
          planYear: plan.planYear,
          quoteId: adminContext?.clientView?.quoteId || getQuoteId(),
        }))
    );
    setMgComparablePlans(
      plans
        .filter((p) => p.type === ComparablePlanType.Mg)
        .map((plan) => ({
          bidId: plan.bidId,
          zip: plan.zip,
          countyName: plan.county,
          effectiveDate: plan.effectiveDate,
          quoteId: adminContext?.clientView?.quoteId || getQuoteId(),
        }))
    );
    setPdpComparePlans(
      plans
        .filter((p) => p.type === ComparablePlanType.Pdp)
        .map((plan) => ({
          bidId: plan.bidId,
          zip: plan.zip,
          countyName: plan.county,
          planYear: plan.planYear,
          pdpPrimary: !!plan.pdpPrimary,
        }))
    );
    setMgBids(
      plans.filter((p) => p.type === ComparablePlanType.Mg).map((p) => p.bidId)
    );
  }, [plans]);

  const mgPlans = React.useMemo(() => {
    if (mgPlansInput?.length) {
      return addDetailsToMGPlanList(mgPlansInput);
    }
    return [];
  }, [mgPlansInput]);

  const GetImageBlob = React.useCallback(
    async (callback: (data: any) => void) => {
      if (ref.current) {
        window.scrollTo(0, 0);
        return html2canvas(ref.current, { useCORS: true }).then((canvas) =>
          canvas.toBlob(callback)
        );
      }
      return undefined;
    },
    [ref]
  );

  React.useEffect(() => {
    if (screenshotProcess) {
      GetImageBlob((data: any) => {
        if (data) {
          const dataURL = URL.createObjectURL(data);
          const link = document.createElement('a');
          link.download =
            [
              adminContext?.clientView.firstName,
              adminContext?.clientView.lastName,
            ].join('_') + '_compare_offer.png';
          link.href = dataURL;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setScreenshotProcess(false);
        }
      });
    }
  }, [screenshotProcess, adminContext]);

  const SaveInHistory = (callback?: () => void) => {
    return GetImageBlob((data: any) => {
      if (data) {
        const formData = new FormData();

        formData.append('screenshot', data);
        if (clientId) {
          formData.append('clientId', clientId);
        }

        return fetch(
          environment.clientFilesApiUrl + '/api/plan-comparison-screenshot',
          {
            method: 'POST',
            body: formData,
            headers: {
              authorization: 'Bearer ' + keycloak?.token,
            },
          }
        )
          .then((response) => response.json())
          .then(({ link }) =>
            saveInHistory({
              variables: {
                input: {
                  clientId,
                  screenshotLink: link,
                },
              },
            })
          )
          .catch((error) => {
            console.error('Error:', error);
          })
          .finally(() => {
            setSaveProcess(false);
            callback && callback();
          });
      } else {
        setSaveProcess(false);
        return false;
      }
    });
  };

  const Refresh = () => {
    setRefreshProcess(true);
    SaveInHistory(() =>
      refreshPlans()
        .then(refetch)
        .then(() => enqueueSnackbar('Plans updated', { variant: 'success' }))
        .finally(() => setRefreshProcess(false))
    );
  };

  if (loading || maLoading || mgLoading) {
    return <V3Preloader />;
  }

  if (!maPlans.length && !mgPlans.length) {
    return (
      <Box
        sx={{
          mt: '20vh',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          flex: 1,
        }}
      >
        <Card sx={{ p: '20px 30px', maxWidth: 700 }}>
          <Stack spacing={'15px'}>
            <Typography className={'fs-20 bold lh-27'}>
              Your Licenced Medicare Advisor is working on your customized plan
              options
            </Typography>
            <Typography className={'fs-16 lh-20'}>
              Your advisor will upload your personalized Medicare plan once
              completed to this page. You will receive multiple Medicare options
              to consider. After reviewing your plan your advisor will be
              available to answer any questions and help you enroll.
            </Typography>
            <Typography className={'fs-16 lh-20'}>
              Speak with a Medicare Advisor now.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flex: 1,
              }}
            >
              <ScheduleACallButton
                className={'gtm-button-event w-150'}
                data-gtm-button-name={'BOOK_A_CALL_BUTTON'}
              >
                Book a call
              </ScheduleACallButton>
            </Box>
          </Stack>
        </Card>
      </Box>
    );
  }

  return (
    <Box sx={styles}>
      <Drawer
        PaperProps={{
          sx: {
            width: '226px',
          },
        }}
        anchor={'right'}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <CompareOfferHistoryList />
      </Drawer>
      {!!adminContext && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            position: 'relative',
            left,
          }}
        >
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              variant={'outlined'}
              color={'primary'}
              onClick={() => {
                setRefreshProcess(true);
                Refresh();
              }}
            >
              Refresh
              {refreshProcess && (
                <CircularProgress
                  size={10}
                  sx={{ ml: 1, color: colors.custom.green.variant2 }}
                />
              )}
            </Button>
            <Button
              variant={'outlined'}
              color={'primary'}
              onClick={() => setScreenshotProcess(true)}
            >
              Download
            </Button>
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              variant={'outlined'}
              color={'primary'}
              onClick={() => setDrawerOpen(true)}
            >
              History
            </Button>
            <Button
              variant={'outlined'}
              color={'primary'}
              onClick={() => {
                setSaveProcess(true);
                SaveInHistory();
              }}
            >
              Save
              {saveProcess && (
                <CircularProgress
                  size={10}
                  sx={{ ml: 1, color: colors.custom.green.variant2 }}
                />
              )}
            </Button>
          </Box>
        </Box>
      )}
      <Box
        display={'flex'}
        mb={3}
        sx={{
          position: 'sticky',
          left: 0,
          zIndex: 1,
        }}
      >
        {firstName && (
          <Typography className={'medium fs-24'}>
            {firstName + ' ' + lastName}, here is a comparison of your options
          </Typography>
        )}
        {countyName && (
          <Typography
            sx={{ color: colors.custom.green.variant2 }}
            className={'ml-20 bold fs-24'}
          >
            {countyName}, {state}
          </Typography>
        )}
      </Box>
      <table ref={ref}>
        <thead>
          <tr>
            <td>
              <Box
                component={'img'}
                sx={{}}
                src={'/assets/img/Logo.svg'}
                width={121}
                height={22}
              />
            </td>
            {!!mgPlans.length && (
              <td
                className={'medigap bold white text-align-centered'}
                colSpan={mgPlans.length}
              >
                Medicare Supplement + Prescription Drug Plan{' '}
              </td>
            )}
            {!!maPlans.length && (
              <td
                className={'medicare bold white text-align-centered'}
                colSpan={maPlans.length}
              >
                Medicare Advantage
              </td>
            )}
          </tr>
        </thead>
        <tbody>
          <TableRow
            title={'Plan Type'}
            mgPlans={mgPlans}
            maPlans={maPlans}
            getMgCellContent={(plan) => (
              <td className={'bold'}>Plan {plan.planName}</td>
            )}
            getMaContent={(plans) => (
              <td
                style={{ background: '#f1f1f1' }}
                className={'text-align-centered fs-15'}
                colSpan={maPlans.length}
              >
                Below are the lowest cost plans based on your doctors and drugs
              </td>
            )}
          />

          <TableRow
            title={'Effective Date'}
            mgPlans={mgPlans}
            maPlans={maPlans}
            getMgCellContent={(plan) => (
              <td className={'medium'}>
                {mgComparablePlans.find((p) => plan.key === p.bidId)
                  ?.effectiveDate ? (
                  moment(
                    mgComparablePlans.find((p) => plan.key === p.bidId)
                      ?.effectiveDate
                  ).format('YYYY-MM-DD')
                ) : (
                  <>&#8212;</>
                )}
              </td>
            )}
            getMaCellContent={(plan) => <td className={'medium'}>&#8212;</td>}
          />
          <TableRow
            title={'Monthly Premium'}
            subtitle={'Med Supp is based on lowest cost plan available'}
            mgPlans={mgPlans}
            maPlans={maPlans}
            getMgCellContent={(plan) => (
              <td className={'header bold fs-18'}>
                {plan.monthlyPremium}
                <Box
                  component={'a'}
                  sx={{ display: 'block' }}
                  href={`${environment.planFinderUrl}plan-finder-mg`}
                  className={'fs-12'}
                >
                  {plan.title}
                </Box>
                <Logo
                  url={getMedigapCompanyLogo(plan.parentOrgName, plan.orgName)}
                />
              </td>
            )}
            getMaCellContent={(plan) => (
              <td className={'header bold fs-18'}>
                {plan.monthlyPremium}
                <Box
                  component={'a'}
                  sx={{ display: 'block' }}
                  href={`${environment.planFinderUrl}plan-finder-ma/${plan.bidId}`}
                  className={'fs-12'}
                >
                  {plan.planName}
                </Box>
                <Logo url={getCompanyLogo(plan.parentOrgName, plan.orgName)} />
              </td>
            )}
          />
          <TableRow
            title={'Doctor Coverage'}
            mgPlans={mgPlans}
            maPlans={maPlans}
            getMgCellContent={(plan) => (
              <td>Any doctor that accepts Medicare</td>
            )}
            getMaCellContent={(plan) => (
              <td>
                {plan.doctorsCoverage.length ? (
                  <DoctorCoverageList
                    length={100}
                    hideTitle
                    isPPO={plan.planType === 'PPO'}
                    coverage={plan.doctorsCoverage}
                  />
                ) : plan.planType === 'HMO' ? (
                  'Only in-network doctors are covered'
                ) : (
                  'Out-of-network doctors are covered'
                )}
              </td>
            )}
          />
          <TableRow
            title={'Deductible'}
            mgPlans={mgPlans}
            maPlans={maPlans}
            getMgCellContent={(plan) => <td>{plan.partBDeductible}</td>}
            getMaCellContent={(plan) => (
              <td>{plan.inNetworkDeductableAmount}</td>
            )}
          />
          <TableRow
            title={'Maximum Out-of-pocket'}
            mgPlans={mgPlans}
            maPlans={maPlans}
            getMgCellContent={(plan) => <td>{plan.outOfPocketLimits}</td>}
            getMaCellContent={(plan) => (
              <td>
                {plan.outOfPocketMaximum.map((v) => (
                  <div className={'mb-4'}>{v}</div>
                ))}
              </td>
            )}
          />
          <TableRow
            title={'Medical/Hospital Costs'}
            mgPlans={mgPlans}
            maPlans={maPlans}
            getMgCellContent={(plan) => (
              <Box component={'td'} sx={{ '& img': { display: 'none' } }}>
                {plan.doctorAndOutpatientServices}
              </Box>
            )}
            getMaCellContent={(plan) => (
              <td>
                <div className={'bold'}>Copays (in-/out- of network):</div>
                <ul>
                  <li>
                    Primary doctor:{' '}
                    {plan?.primaryCarePhysicianInNetwork || 'N/A'} /{' '}
                    {plan?.primaryCarePhysicianOutOfNetwork || 'N/A'}
                  </li>
                  <li>
                    Specialist:{' '}
                    {getNumbersFromString(plan?.physicianSpecialist[0] || '')[0]
                      ? '$' +
                        getNumbersFromString(
                          plan?.physicianSpecialist[0] || ''
                        )[0]
                      : 'N/A'}{' '}
                    /{' '}
                    {getNumbersFromString(plan?.physicianSpecialist[1] || '')[0]
                      ? '$' +
                        getNumbersFromString(
                          plan?.physicianSpecialist[1] || ''
                        )[0]
                      : 'N/A'}
                  </li>
                </ul>
                <div className={'bold'}>Hospital stay (in-network):</div>
                <ul>
                  <li>{plan.firstWeekOfStay || 'N/A'}</li>
                </ul>
              </td>
            )}
          />
          <TableRow
            mgPlans={mgPlans}
            maPlans={maPlans}
            title={'Part B Giveback'}
            getMgCellContent={(plan) => <td>&#8212;</td>}
            getMaCellContent={(plan) => (
              <td>
                <Benefit
                  label={plan.partBGiveBack ? `${plan.partBAmount}` : 'No'}
                  value={!!plan.partBGiveBack}
                />
              </td>
            )}
          />
          <tr>
            <td
              className={'bg-white h-10'}
              colSpan={1 + maPlans.length + mgPlans.length}
            />
          </tr>
          <TableRow
            title={'Prescription Drug Costs'}
            mgPlans={mgPlans}
            maPlans={maPlans}
            getMgContent={(plan) => (
              <td style={{ background: '#f1f1f1' }} colSpan={mgPlans.length}>
                {!!pdpPlan && (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      alignItems: 'center',
                      justifyContent:
                        pdpPlans.length > 1 && !screenshotProcess
                          ? 'flex-end'
                          : 'center',
                    }}
                  >
                    <div className={'fs-14 text-align-centered'}>
                      <Box
                        component={'a'}
                        sx={{ display: 'block' }}
                        href={`${environment.planFinderUrl}plan-finder-pdp#${pdpPlan.bidId}`}
                      >
                        <b>{pdpPlan.planName}</b>
                      </Box>
                      <i className={'fs-11'}>
                        Lowest cost plan based on your drugs
                      </i>
                    </div>
                    {pdpPlans.length > 1 && !screenshotProcess && (
                      <>
                        <StyledSelect
                          label={''}
                          data-test={'pdp-plan-select'}
                          placeholder={'Select pdp plan'}
                          labelClassName={'fs-12 lh-15'}
                          className={'w-150'}
                          value={pdpPlan?.bidId || ''}
                          onChange={(event) => {
                            changePdpPrimary(event.target.value as string);
                          }}
                        >
                          {pdpPlans.map((p) => (
                            <MenuItem key={p.bidId} value={p.bidId}>
                              {p.planName}
                            </MenuItem>
                          ))}
                        </StyledSelect>
                        <Link
                          to={
                            PageRoutes.pdpCompareOffer +
                            (adminContext?.clientId
                              ? `?clientId=${adminContext.clientId}`
                              : '')
                          }
                          className={'fs-13 underline pointer'}
                        >
                          PDP compare
                        </Link>
                      </>
                    )}
                  </Box>
                )}
                {!pdpPlan && (
                  <div className={'fs-14 text-align-centered'}>
                    <b>N/A</b>
                  </div>
                )}
              </td>
            )}
            getMaCellContent={(plan) => (
              <td
                style={{ background: '#f1f1f1' }}
                className={'fs-14 text-align-centered bold'}
              >
                Bundled with plan at no extra premium
              </td>
            )}
          />
          <TableRow
            title={'Monthly Premium'}
            mgPlans={mgPlans}
            maPlans={maPlans}
            getMgCellContent={(plan) => (
              <td className={'bold fs-18'}>
                {!!pdpPlan && pdpPlan.monthlyPremium}
                {!pdpPlan && 'N/A'}
              </td>
            )}
            getMaCellContent={(plan) => <td className={'bold fs-18'}>N/A</td>}
          />
          <TableRow
            title={'Drug Coverage'}
            mgPlans={mgPlans}
            maPlans={maPlans}
            getMgCellContent={(plan) => (
              <td>
                {pdpPlan ? (
                  <DrugCoverageList
                    length={100}
                    hideTitle
                    coverage={pdpPlan.drugsCoverage}
                  />
                ) : (
                  'N/A'
                )}
              </td>
            )}
            getMaCellContent={(plan) => (
              <td>
                {plan.drugsCoverage.length ? (
                  <DrugCoverageList
                    length={100}
                    hideTitle
                    coverage={plan.drugsCoverage}
                  />
                ) : (
                  'N/A'
                )}
              </td>
            )}
          />
          <TableRow
            title={'Deductible'}
            mgPlans={mgPlans}
            maPlans={maPlans}
            getMgCellContent={(plan) => (
              <td>
                {!!pdpPlan &&
                  [
                    pdpPlan?.drugDetails?.mrxAltDedAmount || '',
                    pdpPlan?.drugDetails?.mrxAltNoDedTier || '',
                  ].join('\n')}
                {!pdpPlan && 'N/A'}
              </td>
            )}
            getMaCellContent={(plan) => (
              <td>
                {[
                  plan?.drugDetails?.mrxAltDedAmount || '',
                  plan?.drugDetails?.mrxAltNoDedTier || '',
                ].join('\n')}
              </td>
            )}
          />
          <TableRow
            title={'Monthly drug costs (Estimates)'}
            subtitle={'Cost based on filling at a Preferred Retail Pharmacy'}
            mgPlans={mgPlans}
            maPlans={maPlans}
            getMgCellContent={(plan) => (
              <td>
                <GetDrugCost plan={pdpPlan} />
              </td>
            )}
            getMaCellContent={(plan) => (
              <td>
                <GetDrugCost plan={plan} />
              </td>
            )}
          />
          <tr>
            <td
              className={'bg-white h-10'}
              colSpan={1 + maPlans.length + mgPlans.length}
            />
          </tr>
          <TableRow
            title={'Total Estimated Premium'}
            subtitle={'+ Drug Monthly Cost'}
            mgPlans={mgPlans}
            maPlans={maPlans}
            getMgCellContent={(plan) => (
              <td style={{ background: '#dae2f3' }} className={'fs-18'}>
                <b>
                  {toCurrency(
                    getNumbersFromString(plan.monthlyPremium || '0')[0] +
                      getNumbersFromString(pdpPlan?.monthlyPremium || '0')[0],
                    2,
                    2
                  )}
                </b>
                <br />
                <i className={'fs-11 no-wrap'}>
                  + average monthly drug costs (
                  {getDrugCostDiapason(GetDrugCostArray(pdpPlan)) || 'N/A'})
                </i>
              </td>
            )}
            getMaCellContent={(plan) => (
              <td style={{ background: '#dae2f3' }} className={'fs-18'}>
                <b>{plan.monthlyPremium || 'N/A'}</b>
                <br />
                <i className={'fs-11 no-wrap'}>
                  + average monthly drug costs (
                  {getDrugCostDiapason(GetDrugCostArray(plan)) || 'N/A'})
                </i>
              </td>
            )}
          />
          <tr>
            <td
              className={'bg-white h-10'}
              colSpan={1 + maPlans.length + mgPlans.length}
            />
          </tr>
          <TableRow
            title={'Extra Benefits '}
            mgPlans={mgPlans}
            maPlans={maPlans}
            getMgCellContent={(plan) => <td>&#8212;</td>}
            getMaCellContent={(plan) => (
              <td>
                {Object.keys(plan.extraBenefits).map((v) => (
                  <Benefit
                    sx={{ mb: '5px' }}
                    label={_.startCase(v)}
                    value={(plan.extraBenefits as any)[v]}
                  />
                ))}
              </td>
            )}
          />
        </tbody>
      </table>
    </Box>
  );
}

export default withCompareOfferContext(CompareOffer);

const Logo = ({ url }: { url: string }) => (
  <div>
    <Box
      sx={{
        height: 45,
        width: 150,
        minWidth: 150,
        marginTop: 0,
        mt: 1,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'left',
      }}
      style={{ backgroundImage: `url("${url}")` }}
    />
  </div>
);

const getDrugCostDiapason = (costs: Array<Array<string>>) => {
  const min = _.min(costs.map((v) => getNumbersFromString(v[1])[0]));
  const max = _.max(costs.map((v) => getNumbersFromString(v[1])[0]));
  if (isNaN(min as number)) {
    return <>&#8212;</>;
  }
  return min === max || isNaN(max as number)
    ? toCurrency(min as number)
    : toCurrency(min as number) + ' - ' + toCurrency(max as number);
};

const GetDrugCost = ({ plan }: { plan: any }) => {
  if (!plan?.drugCostMonthlyBreakdown?.monthlyCosts?.length) {
    return <div>&#8212;</div>;
  } else {
    return (
      <>
        Preferred Retail:
        <ul>
          {GetDrugCostArray(plan).map((v) => (
            <li key={v[0]}>
              <b>Month {v[0]}:</b> ~{v[1]}
            </li>
          ))}
        </ul>
      </>
    );
  }
};

const GetDrugCostArray = (plan: any) => {
  if (!plan?.drugCostMonthlyBreakdown?.monthlyCosts?.length) {
    return [];
  } else {
    const output = _.map(plan.drugCostMonthlyBreakdown?.monthlyCosts, 'amount');
    let lastMonth = 0;
    return [
      ..._.uniq(output).map((v, i) => {
        if (i === _.uniq(output).length - 1) {
          return [`${lastMonth + 1}+`, toCurrency(v, 0, 0)];
        } else {
          const newLastMonth = lastMonth + output.filter((i) => i === v).length;
          const duration = newLastMonth - lastMonth;
          let str;
          if (duration > 1) {
            str = lastMonth + 1 + '-' + newLastMonth;
          } else {
            str = newLastMonth;
          }
          lastMonth = newLastMonth;
          return [`${str}`, toCurrency(v, 0, 0)];
        }
      }),
    ];
  }
};

interface TableRowProps {
  mgPlans: MedigapPlanOutputWithDetails[];
  maPlans: PlanWithTiers[];
  getMgContent?: (plans: MedigapPlanOutputWithDetails[]) => JSX.Element;
  getMgCellContent?: (plan: MedigapPlanOutputWithDetails) => JSX.Element;
  getMaContent?: (plans: PlanWithTiers[]) => JSX.Element;
  getMaCellContent?: (plan: PlanWithTiers) => JSX.Element;
  title: string;
  subtitle?: string;
}

const TableRow = (props: TableRowProps) => {
  return (
    <tr>
      <td>
        <Typography
          sx={{ whiteSpace: 'break-spaces' }}
          className={'bold lh-14'}
        >
          {props.title}{' '}
          <i className={'fs-12 regular'}>
            <br />
            {props.subtitle}
          </i>
        </Typography>
      </td>
      {!!props.mgPlans.length &&
        (props.getMgCellContent
          ? props.mgPlans.map(props.getMgCellContent)
          : props.getMgContent && props.getMgContent(props.mgPlans))}
      {!!props.maPlans.length &&
        (props.getMaCellContent
          ? props.maPlans.map(props.getMaCellContent)
          : props.getMaContent && props.getMaContent(props.maPlans))}
    </tr>
  );
};

const StyledSelect = styled(SelectInput)({
  marginBottom: '0!important',
  '& div.MuiOutlinedInput-input': {
    fontSize: '14px!important',
    fontWeight: '400!important',
    padding: '9px 9px 5px!important',
    zIndex: 1,
    lineHeight: '17px!important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    marginTop: '1px!important',
    height: '39px!important',
  },
});
