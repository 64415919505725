import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useLogEvent } from '@coverright/shared/analytics';

export const TrustpilotWidget = ({width}: {width?: number}) => {
  const logEvent = useLogEvent();

  const koef = React.useMemo(() => {
    return !width ? 1 : width / 105;
  }, [width])

  return <Box sx={{cursor: 'pointer'}} onClick={() => {
    logEvent('click', {
      element_title: 'Trustpilot',
      element_id: 'trustpilot-banner'
    }).then(() => window.open('https://www.trustpilot.com/review/coverright.com'))
  }}>
    <Box mb={0.4} display={'flex'} alignItems={'center'}>
      <Box component={'img'} sx={{minWidth: Math.round(15  * koef), maxWidth: Math.round(15  * koef)}} src={'assets/img/TrustPilotStar.png'} />
      <Typography className={`medium fs-${Math.round(11  * koef)}`} sx={{ml: .2, mt: .8, color: '#000'}}>Trustpilot</Typography>
    </Box>
    <Box component={'img'} sx={{minWidth: Math.round(105  * koef), maxWidth: Math.round(105  * koef)}} src={'assets/img/TrustPilot.svg'} />
    <Typography className={`fs-${Math.round(11  * koef)}`} sx={{ml: .2, mt: .6, color: '#000'}}>TrustScore <span className={'semi-bold'}>4.9</span></Typography>
  </Box>
}
