import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './app/app';
import { initTrackEvent } from '@coverright/shared/analytics';
import { initKeycloak } from '@coverright/shared/keycloak';
import { Providers } from './app/providers';
import { fillStoredUserFromUrlParams } from '@coverright/features';
import { client } from '@coverright/data-access/apollo-clients';

const root = createRoot(document.getElementById('root') as HTMLElement);

initApp().then(() => {
  root.render(
    <Providers>
      <App />
    </Providers>
  );
});

async function initApp() {
  await initKeycloak();
  let flow = 'integrated-flow';
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('product')) {
    flow = urlParams.get('product') as string;
  }
  initTrackEvent(flow);
  await fillStoredUserFromUrlParams(client);
}
