import * as React from 'react';
import { KeycloakContext } from '@coverright/shared/keycloak';
import { Box, Card, Collapse, Typography } from '@mui/material';
import { SxProps } from '@mui/material';
import { colors } from '@coverright/ui/themes';
import { ScheduleACallButton } from '@coverright/features';
import { environment } from '../../environments/environment';
import {useContext} from "react";
import {AgentContext} from "@coverright/shared/contexts";

interface AgentCardProps {
  onCreateAccountClick: () => void;
  sx?: SxProps;
}

export const AgentCard = ({ onCreateAccountClick, sx }: AgentCardProps) => {
  const { keycloak } = React.useContext(KeycloakContext);
  const { agent } = useContext(AgentContext);

  return (
    <Collapse in={!!agent}>
      <Card sx={{ p: '22px 14px 18px 28px', ...sx }}>
        <Typography className={'bold fs-24'}>Questions?</Typography>
        <Typography className={'fs-16 lh-20'}>
          Connect with your dedicated licensed advisor.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            mt: 3,
          }}
        >
          {keycloak.authenticated && (
            <>
              {agent && (
                <Box
                  sx={{
                    display: 'flex',
                    flex: 1,
                    gap: '10px',
                    alignItems: 'center',
                    mb: { xs: 3 },
                  }}
                >
                  {agent.picUrl && (
                    <Box
                      style={{
                        backgroundImage: `url("${agent.picUrl}")`,
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                      }}
                      className={'w-70 h-70'}
                    />
                  )}
                  <div>
                    <Typography
                      sx={{ color: colors.custom.green.variant2 }}
                      className={'bold fs-16 lh-20 ls-1'}
                    >
                      {agent.firstName} {agent.lastName}
                    </Typography>
                    <Typography
                      sx={{ color: colors.custom.green.variant2 }}
                      className={'fs-14 lh-17 ls-1'}
                    >
                      {agent.email}
                      <br />
                      <span className={'fs-16'}>{agent.phone}</span>
                    </Typography>
                    <img
                      className={'w-95 h-16 mt-4'}
                      src={'/assets/img/stars.png'}
                    />
                  </div>
                </Box>
              )}
              {!agent && (
                <Box
                  sx={{
                    display: 'flex',
                    flex: 1,
                    gap: '10px',
                    alignItems: 'center',
                    mb: { xs: 3 },
                  }}
                >
                  <ScheduleACallButton
                    className={'gtm-button-event w-150'}
                    data-gtm-button-name={'BOOK_A_CALL_BUTTON'}
                  >
                    Book a call
                  </ScheduleACallButton>
                </Box>
              )}
            </>
          )}
          {!keycloak.authenticated && (
            <Box
              data-test={'agent_card_create_account_button'}
              onClick={onCreateAccountClick}
              sx={{
                display: 'flex',
                cursor: 'pointer',
                flex: 1,
                gap: '10px',
                alignItems: 'center',
                mb: { xs: 3 },
              }}
            >
              <img
                className={'w-70 h-70'}
                src={'/assets/img/create-account.svg'}
              />
              <div>
                <Typography
                  sx={{ color: colors.custom.green.variant2 }}
                  className={'bold fs-16 lh-20 ls-1'}
                >
                  Create your account
                </Typography>
                <Typography
                  sx={{ color: colors.custom.green.variant2 }}
                  className={'fs-14 lh-17 ls-1'}
                >
                  Your dedicated advisor will be allocated once you create an
                  account.
                </Typography>
              </div>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: { xs: 'column' },
              justifyContent: 'center',
              alignItems: { xs: 'flex-start' },
            }}
          >
            <div>
              <Typography className={'fs-16 lh-20'}>
                Mon - Fri: <span className={'bold'}>8am - 10pm ET</span>
              </Typography>
              <Typography className={'fs-16 lh-20'}>
                Sat - Sun: <span className={'bold'}>9am - 7pm ET</span>
              </Typography>
            </div>
          </Box>
        </Box>
      </Card>
    </Collapse>
  );
};
