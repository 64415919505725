import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { AppContext } from '../AppContext';

const fetchABTesting = async () => {
  const data = await fetch(
    process.env.NX_STRAPI + `/api/a-b-tests`,
    {
      headers: {
        "content-type": "application/json",
        'Authorization': 'Bearer ' + process.env.NX_STRAPI_TOKEN
      },
      cache: 'no-cache',
    }
  ).then(res => res.json())
  const tests: ABTest[] = data.data.map((i: {attributes: ABTest}) => i.attributes);

  return tests.filter(t => {
    if (t.stagingOnly && process.env.NX_PRODUCTION === 'true') {
      return false
    }

    if (t.from && t.to) {
      return moment().isBetween(moment(t.from), moment(t.to));
    } else if (t.from) {
      return moment().isAfter(moment(t.from))
    } else if (t.to) {
      return moment().isBefore(moment(t.to))
    }
    return true;
  })
}


export function useAbTests() {
  const [state, dispatch, loaded] = React.useContext(AppContext);
  const [abTests, setAbTests] = React.useState<ABTest[]>([]);
  const [abTestConfig, setAbTestConfig] = useState<ABTestConfig>({});

  const activeTests = useMemo(() => abTests.filter(t => t.active).map(t => t.key), [abTests])

  useEffect(() => {
    fetchABTesting().then(setAbTests)
  }, []);

  useEffect(() => {
    if (activeTests.length) {
      const localStored: any = localStorage.getItem('abTest') ? JSON.parse(localStorage.getItem('abTest')!) : {};
      const storedValue = {...localStored, ...(state?.state?.abTesting || {})};
      const value = activeTests.reduce((prev, test) => ({
        ...prev,
        [test]: storedValue[test] || generateABVariant()
      }), {} as ABTestConfig )
      localStorage.setItem('abTest', JSON.stringify(value));
      setAbTestConfig(value)
    }
  }, [loaded, state?.state?.abTesting, activeTests]);

  return abTestConfig;
}

const generateABVariant = (): ABVariant => (
  Math.random() > .5 ? ABVariant.b : ABVariant.a
)

type ABTestType = 'pre_congrats_modal'

interface ABTest {
  active: boolean,
  stagingOnly: boolean,
  from: string | null,
  to: string | null,
  key: ABTestType,
}

export type ABTestConfig = {
  [key in ABTestType]?: ABVariant
}

export enum ABVariant {
  a = 'a',
  b = 'b',
  ab = 'ab',
}
