import { Tooltip } from '@coverright/ui/inputs';
import { PageWrapper } from '@coverright/ui/shared';
import React from 'react';
import { AppContext } from '@coverright/shared/contexts';
import { Box, Hidden, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { colors } from '@coverright/ui/themes';
import moment from 'moment';
import { getInitialEnrollmentPeriod, getStoredUser } from '@coverright/utils';
import {tooltips} from '@coverright/utils';
import SituationSelect, { Situation } from './situation-select';
import { useNavigate } from 'react-router-dom';


const styles: {[key: string]: SxProps} = {
  row: {
    display: 'flex',
    flexDirection: {xs: 'column', md: 'row'}
  },
  imgWrapper: {
    display: 'flex',
    mr: {md: '35px'},
    gap: '10px',
    alignItems: {xs: 'center', md: 'flex-start'}
  },
  blueText: {
    fontSize: {xs: 28, md: 40},
    mt: 1,
    color: '#002576',
    lineHeight: {xs: '35px', md: '50px'}
  }
}

const formName = 'enrollmentInfo';

export function EnrollmentInfo() {
  const [{state: {returnToDashboard}}] = React.useContext(AppContext);
  const navigate = useNavigate();
  const [situation, setSituation] = React.useState<Situation>();

  React.useEffect(() => {
    setSituation(undefined)
  }, [])

  const Component = React.useMemo(() => {
      switch(situation) {
        case Situation.BETTER_COVERAGE: return <BetterCoverage />;
        case Situation.REPLACE_CURRENT_PLAN: return <ReplaceCurrentPlan />;
        case Situation.NEW_TO_MEDICARE: return <NewToMedicare />;
        default: return <SituationSelect onChange={setSituation} />;
      }
  }, [situation]);

  const title = React.useMemo(() => {
    if (typeof situation === 'undefined') {
      return 'Please select the option that best reflects your situation...'
    } else {
      return `Thanks${getStoredUser()?.firstName ? ' ' + getStoredUser()?.firstName : ''}! Based on what you’ve told us, here is some important enrollment information...`
    }
  }, [situation]);

  return (
    <PageWrapper title={title}
                 formName={formName}
                 hideBackButton={returnToDashboard}
                 nextButtonTitle={returnToDashboard ? 'Return to dashboard' : undefined}
                 onNextButtonClick={() => navigate('/')}
    >
      {Component}
    </PageWrapper>
  );
}

export default EnrollmentInfo;

const ReplaceCurrentPlan = () => {
  return <Box sx={styles.row}>
    <Box sx={styles.imgWrapper}>
      <img src={'/assets/img/circle-calendar.png'} className={'w-105 h-105'} />
      <Hidden mdUp>
        <Typography className={'fs-29 bold lh-36'} sx={{color: colors.custom.green.variant2}}>When can you replace your plan?</Typography>
      </Hidden>
    </Box>
    <Box>
      <Hidden mdDown>
        <Typography className={'fs-29 bold lh-36'} sx={{color: colors.custom.green.variant2}}>When can you replace your plan?</Typography>
      </Hidden>
      <Typography className={'mt-8 fs-18 medium lh-22'}>If you’re replacing your current Medicare plan outside of the Annual Open Enrollment Period (Oct 15 - Dec 7), you will typically need to qualify for a Special Enrollment Period in order to switch plans.
        If you’re not sure, our advisors will help determine if you are eligible.</Typography>
      <Typography className={'mt-40 fs-22 bold lh-27'}>Common eligible events</Typography>
      <Box sx={{mt: '20px', display: 'flex', flexDirection: {xs: 'column', sm: 'row'}}}>
        <Box className={'flex flex-align-center'}>
          <img src={'/assets/img/moving-residence.png'} className={'w-80 h-80 mr-20'} />
          <Hidden smUp>
            <Typography className={'fs-25 lh-31'} sx={{color: '#002576'}}>Moving residence</Typography>
          </Hidden>
        </Box>
        <div>
          <Hidden smDown>
            <Typography className={'fs-25 lh-31'} sx={{color: '#002576'}}>Moving residence</Typography>
          </Hidden>
          <Typography className={'mt-8 fs-16 lh-20'} color={'textSecondary'}>Your chance to switch plans begins the month before the month you move and continues for 2 full months after you move.</Typography>
        </div>
      </Box>
      <Box sx={{mt: '20px', display: 'flex', flexDirection: {xs: 'column', sm: 'row'}}}>
        <Box className={'flex flex-align-center'}>
          <img src={'/assets/img/medicaid-circle.png'} className={'w-80 h-80 mr-20'} />
          <Hidden smUp>
            <Typography className={'fs-25 lh-31'} sx={{color: '#002576'}}>Eligibility for Medicaid / Extra Help</Typography>
          </Hidden>
        </Box>
        <div>
          <Hidden smDown>
            <Typography className={'fs-25 lh-31'} sx={{color: '#002576'}}>Eligibility for Medicaid / Extra Help</Typography>
          </Hidden>
          <Typography className={'mt-8 fs-16 lh-20'} color={'textSecondary'}>If you became eligible for <Tooltip title={tooltips.medicaid} arrow><span className={'medium dashed-underline'}>Medicaid</span></Tooltip> or <Tooltip title={tooltips.extraHelp} arrow><span className={'medium dashed-underline'}>Extra Help</span></Tooltip>, you can switch plans the month you become eligible and then once every quarter between January and September each year.</Typography>
        </div>
      </Box>
      <Box sx={{mt: '20px', display: 'flex', flexDirection: {xs: 'column', sm: 'row'}}}>
        <Box className={'flex flex-align-center'}>
          <img src={'/assets/img/other-circle.png'} className={'w-80 h-80 mr-20'} />
          <Hidden smUp>
            <Typography className={'fs-25 lh-31'} sx={{color: '#002576'}}>Other</Typography>
          </Hidden>
        </Box>
        <div>
          <Hidden smDown>
            <Typography className={'fs-25 lh-31'} sx={{color: '#002576'}}>Other</Typography>
          </Hidden>
          <Typography className={'mt-8 fs-16 lh-20'} color={'textSecondary'}>Other special circumstances or events may also qualify you for a Special Enrollment Period.  If you think you qualify and want to check if any apply to you, call us or visit this <a href={'https://www.medicare.gov/sign-up-change-plans/when-can-i-join-a-health-or-drug-plan/special-circumstances-special-enrollment-periods'} target={'_blank'}>link</a> to learn more.</Typography>
        </div>
      </Box>
      <div className={'h-50'} />
    </Box>
  </Box>
}

const BetterCoverage = () => {
  return <Box sx={styles.row}>
    <Box sx={styles.imgWrapper}>
      <img src={'/assets/img/circle-calendar.png'} className={'w-105 h-105'} />
      <Hidden mdUp>
        <Typography className={'fs-29 bold lh-36'} sx={{color: colors.custom.green.variant2}}>When can you switch plans?</Typography>
      </Hidden>
    </Box>
    <div>
      <Hidden mdDown>
        <Typography className={'fs-29 bold lh-36'} sx={{color: colors.custom.green.variant2}}>When can you switch plans?</Typography>
      </Hidden>

      <Typography className={'mt-20 fs-22 bold lh-27'}>Medicare Supplement (Medigap)</Typography>
      <Typography className={'mt-8 fs-18 medium lh-22'}>Enrollment Period:</Typography>
      <Typography sx={styles.blueText}>Year-round</Typography>
      <Typography className={'mt-8 fs-16 lh-20'} color={'textSecondary'}>You can apply year-round for <Tooltip title={tooltips.medicareSupplement} arrow><span className={'medium dashed-underline'}>Medicare Supplement (or Medigap)</span></Tooltip> coverage.  While there are no medical exams, you will typically be asked a number of health questions by the insurance company to determine if you’re eligible to be covered.</Typography>

      <Typography className={'mt-60 fs-22 bold lh-27'}>Medicare Advantage / Prescription Drug Plans (PDP)</Typography>
      <Typography className={'mt-8 fs-18 medium lh-22'}>Annual Open Enrollment Period:</Typography>
      <Typography sx={styles.blueText}>Oct 15 - Dec 7</Typography>
      <Typography className={'mt-8 fs-16 lh-20'} color={'textSecondary'}>
        If you are currently enrolled in <Tooltip title={tooltips.medicareAdvantage} arrow><span className={'medium dashed-underline'}>Medicare Advantage</span></Tooltip> or standalone <Tooltip title={tooltips.pdp} arrow><span className={'medium dashed-underline'}>Prescription Drug Plan (PDP)</span></Tooltip>, you are generally not allowed to switch plans except during the Annual Open Enrollment Period.
      </Typography>

      <Typography className={'mt-40 fs-22 bold lh-27'}>Exceptions:</Typography>
      <Box sx={{mt: '20px', display: 'flex', flexDirection: {xs: 'column', sm: 'row'}}}>
        <Box className={'flex flex-align-center'}>
          <img src={'/assets/img/open-enrollment.png'} className={'w-80 h-80 mr-20'} />
          <Hidden smUp>
            <Typography className={'fs-25 lh-31'} sx={{color: '#002576'}}>Medicare Advantage Open Enrollment</Typography>
          </Hidden>
        </Box>
        <div>
          <Hidden smDown>
            <Typography className={'fs-25 lh-31'} sx={{color: '#002576'}}>Medicare Advantage Open Enrollment</Typography>
          </Hidden>
          <Typography className={'mt-8 fs-16 lh-20'} color={'textSecondary'}>If you are enrolled in a Medicare Advantage plan on January 1 of each calendar year.  You have the option to shop for a better plan once between <span className={'bold'}>Jan 1 - Mar 31</span>.</Typography>
        </div>
      </Box>

      <Box sx={{mt: '20px', display: 'flex', flexDirection: {xs: 'column', sm: 'row'}}}>
        <Box className={'flex flex-align-center'}>
          <img src={'/assets/img/star-rating.png'} className={'w-80 h-80 mr-20'} />
          <Hidden smUp>
            <Typography className={'fs-25 lh-31'} sx={{color: '#002576'}}>Switching to a 5-star plan</Typography>
          </Hidden>
        </Box>
        <div>
          <Hidden smDown>
            <Typography className={'fs-25 lh-31'} sx={{color: '#002576'}}>Switching to a 5-star plan</Typography>
          </Hidden>
          <Typography className={'mt-8 fs-16 lh-20'} color={'textSecondary'}>If a Medicare Advantage or PDP plan with a 5-star <Tooltip title={tooltips.starRating} arrow><span className={'medium underline'}>CMS Star rating</span></Tooltip> is available in your area, you can make the switch from your current Medicare plan once during the year.</Typography>
        </div>
      </Box>

      <div className={'h-50'} />
    </div>
  </Box>
}

const NewToMedicare = () => {

  const Component = React.useMemo(() => {
      const ageInMonths = Math.floor(moment.duration(moment().diff(moment(getStoredUser()?.birthDate))).asMonths());
      switch (true) {
        case ageInMonths < 768: return <Under />; // less 64y
        case ageInMonths >= 768 && ageInMonths < 784: return <Inside />; // over 64y, less 65y3m
        default: return <After />
      }
  }, [getStoredUser()?.birthDate]);

  return Component;
}

const Under = () => {
  return <div>
    <Box sx={styles.row}>
      <Box sx={styles.imgWrapper}>
        <img src={'/assets/img/circle-calendar.png'} className={'w-105 h-105'} />
        <Hidden mdUp>
          <Typography className={'fs-29 bold lh-36'} sx={{color: colors.custom.green.variant2}}>Enrollment Period</Typography>
        </Hidden>
      </Box>
      <div>
        <Hidden mdDown>
          <Typography className={'fs-29 bold lh-36'} sx={{color: colors.custom.green.variant2}}>Enrollment Period</Typography>
        </Hidden>
        <Typography className={'mt-8 fs-18 medium lh-22'}>Your initial enrollment period is:</Typography>
        <Typography sx={styles.blueText}>{getIEP()}</Typography>
        <Typography className={'mt-8 fs-16 lh-20'} color={'textSecondary'}>You have a 7-month period around your 65th birthday to enroll in Medicare and select a plan.  Enroll during this period to avoid <Tooltip title={tooltips.lateEnrollmentPenalties} arrow><span className={'medium dashed-underline'}>late enrollment penalties</span></Tooltip>.</Typography>

        <Typography className={'mt-30 fs-18 medium lh-22'}>The earliest your Medicare coverage can start is:</Typography>
        <Typography sx={styles.blueText}>{getEarliestDate()}</Typography>
        <Typography className={'mt-8 fs-16 lh-20'} color={'textSecondary'}>{`If you want your coverage to start on this date, we recommend you enroll in a plan no later than ${moment(getEarliestDate()).subtract(1, 'month').date(10).format('LL')}.`}</Typography>
        <div className={'h-50'} />
      </div>
    </Box>
    <Box sx={styles.row}>
      <Box sx={styles.imgWrapper}>
        <img src={'/assets/img/under-65.png'} className={'w-105 h-105'} />
        <Hidden mdUp>
          <Typography className={'fs-29 bold lh-36'} sx={{color: colors.custom.green.variant2}}>Are you under 65 and eligible?</Typography>
        </Hidden>
      </Box>
      <div>
        <Hidden mdDown>
          <Typography className={'fs-29 bold lh-36'} sx={{color: colors.custom.green.variant2}}>Are you under 65 and eligible?</Typography>
        </Hidden>
        <Typography className={'mt-8 fs-18 medium lh-22'}>If you’re under 65, you may be eligible for Medicare if you have received Social Security Disability Insurance (SSDI) for at least: </Typography>
        <Typography sx={styles.blueText}>24 months</Typography>
        <Typography className={'mt-8 fs-16 lh-20'} color={'textSecondary'}>If you qualify because of disability benefits, you will receive your red, white and blue Medicare card during the 3 months before you become eligible for Medicare.  You will need this card to enroll in a private plan.</Typography>
        <div className={'h-50'} />
      </div>
    </Box>
  </div>
}

const After = () => {
  return <div>
    <Box sx={styles.row}>
      <Box sx={styles.imgWrapper}>
        <img src={'/assets/img/circle-calendar.png'} className={'w-105 h-105'} />
        <Hidden mdUp>
          <Typography className={'fs-29 bold lh-36'} sx={{color: colors.custom.green.variant2}}>When can you enroll?</Typography>
        </Hidden>
      </Box>
      <div>
        <Hidden mdDown>
          <Typography className={'fs-29 bold lh-36'} sx={{color: colors.custom.green.variant2}}>When can you enroll?</Typography>
        </Hidden>
        <Typography className={'mt-8 fs-18 medium lh-22'}>Your initial enrollment period was:</Typography>
        <Typography sx={styles.blueText}>{getIEP()}</Typography>
        <Typography className={'mt-8 fs-16 lh-20'} color={'textSecondary'}>You have a 7-month period around your 65th birthday to enroll in Medicare and select a plan. If you miss this period and are not still working, your next available period to enroll is between January to March of each year. </Typography>
        <div className={'h-50'} />
      </div>
    </Box>
    <Box sx={styles.row}>
      <Box sx={styles.imgWrapper}>
        <img src={'/assets/img/bugs-circle.png'} className={'w-105 h-105'} />
        <Hidden mdUp>
          <Typography className={'fs-29 bold lh-36'} sx={{color: colors.custom.green.variant2}}>Working past 65?</Typography>
        </Hidden>
      </Box>
      <div>
        <Hidden mdDown>
          <Typography className={'fs-29 bold lh-36'} sx={{color: colors.custom.green.variant2}}>Working past 65?</Typography>
        </Hidden>
        <Typography className={'mt-8 fs-18 medium lh-22'}>If you are working past 65 or have employer health coverage through a spouse, you should enroll in a Medicare plan within: </Typography>
        <Typography sx={styles.blueText}>2 months <span className={'fs-25'}>of losing employer coverage</span></Typography>
        <Typography className={'mt-8 fs-16 lh-20'} color={'textSecondary'}>You should only ever defer enrollment in Medicare if the employer providing insurance has more than 20 employees.  If not you should enroll into Medicare at the next earliest enrollment period to minimize penalties.</Typography>
        <div className={'h-50'} />
      </div>
    </Box>
  </div>
}

const Inside = () => {
  return <div>
    <Box sx={styles.row}>
      <Box sx={styles.imgWrapper}>
        <img src={'/assets/img/circle-calendar.png'} className={'w-105 h-105'} />
        <Hidden mdUp>
          <Typography className={'fs-29 bold lh-36'} sx={{color: colors.custom.green.variant2}}>When can you enroll?</Typography>
        </Hidden>
      </Box>
      <div>
        <Hidden mdDown>
          <Typography className={'fs-29 bold lh-36'} sx={{color: colors.custom.green.variant2}}>When can you enroll?</Typography>
        </Hidden>
        <Typography className={'mt-8 fs-18 medium lh-22'}>Your initial enrollment period is:</Typography>
        <Typography sx={styles.blueText}>{getIEP()}</Typography>
        <Typography className={'mt-8 fs-16 lh-20'} color={'textSecondary'}>You have a 7-month period around your 65th birthday to enroll in Medicare and select a plan.  Enroll during this period to avoid <Tooltip title={tooltips.lateEnrollmentPenalties} arrow><span className={'medium dashed-underline'}>late enrollment penalties</span></Tooltip>.</Typography>

        <Typography className={'mt-30 fs-18 medium lh-22'}>The earliest your Medicare coverage can start is:</Typography>
        <Typography sx={styles.blueText}>{getEarliestDate()}</Typography>
        <Typography className={'mt-8 fs-16 lh-20'} color={'textSecondary'}>{`If you want your coverage to start on this date, we recommend you enroll in a plan no later than ${moment(getEarliestDate()).subtract(1, 'month').date(10).format('LL')}.`}</Typography>
        <div className={'h-50'} />
      </div>
    </Box>
    <Box sx={styles.row}>
      <Box sx={styles.imgWrapper}>
        <img src={'/assets/img/medicare-circle.png'} className={'w-105 h-105'} />
        <Hidden mdUp>
          <Typography className={'fs-29 bold lh-36'} sx={{color: colors.custom.green.variant2}}>Getting your Medicare card</Typography>
        </Hidden>
      </Box>
      <div>
        <Hidden mdDown>
          <Typography className={'fs-29 bold lh-36'} sx={{color: colors.custom.green.variant2}}>Getting your Medicare card</Typography>
        </Hidden>
        <Typography className={'mt-8 fs-18 medium lh-22'}>You will need your red, white and blue Medicare card in order for us to help enroll you a private plan.</Typography>
        <Typography className={'mt-8 fs-16 lh-22'} color={'textSecondary'}>If you’ve been receiving Social Security payments at least 4 months prior to turning 65, you will automatically receive your card in the 3 months before your birthday. For any other situation, you should to apply for your card via the SSA website:  <a href={'//ssa.gov/benefits/medicare'} target={'_blank'}>ssa.gov/benefits/medicare</a>.</Typography>
        <div className={'h-50'} />
      </div>
    </Box>
    <Box sx={styles.row}>
      <Box sx={styles.imgWrapper}>
        <img src={'/assets/img/bugs-circle.png'} className={'w-105 h-105'} />
        <Hidden mdUp>
          <Typography className={'fs-29 bold lh-36'} sx={{color: colors.custom.green.variant2}}>Working past 65?</Typography>
        </Hidden>
      </Box>
      <div>
        <Hidden mdDown>
          <Typography className={'fs-29 bold lh-36'} sx={{color: colors.custom.green.variant2}}>Working past 65</Typography>
        </Hidden>
        <Typography className={'mt-8 fs-18 medium lh-22'}>If you’re planning to work past 65 or receive employer health coverage through a spouse, we can help assess whether you should defer enrollment.</Typography>
        <Typography className={'mt-8 fs-16 lh-20'} color={'textSecondary'}>If the employer has more than 20 employees, Medicare allows you to defer enrollment after 65 with no <Tooltip title={tooltips.lateEnrollmentPenalties} arrow><span className={'medium dashed-underline'}>late enrollment penalties</span></Tooltip>. Our licensed advisors will help compare and check if it makes sense for you to defer.</Typography>
        <div className={'h-50'} />
      </div>
    </Box>
  </div>
}


const getIEP = () => {
  const IEP = getInitialEnrollmentPeriod();
  return IEP ? IEP.start.format('ll') + ' - ' + IEP.end.format('ll') : ''
}

const getEarliestDate = () => {
  const input = getStoredUser()?.birthDate;
  if (input) {
    const birthdate = moment(input).add( 65, 'year');
    if (birthdate.date() === 1) {
      birthdate.subtract(1, 'months').startOf('month');
    } else {
      birthdate.startOf('month');
    }
    return birthdate.format('ll');
  }
  return '';
}
