import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useGoToMedigap } from '../../hooks/useGoToMedigap';
import { environment } from '../../../environments/environment';
import { getQuoteId, getStoredUser } from '@coverright/utils';
import { useLocation } from 'react-router-dom';

const Conversion = () => {
  const goToMg = useGoToMedigap();
  const {pathname} = useLocation();

  const onClick = () => {
    if ('/conversion-ma' === pathname) {
      const quoteParam = getQuoteId() ? `&quote=${getQuoteId()}` : '';
      document.location.href = `${environment.medicareAdvantageUrl}plans/${getStoredUser()?.zip}/${getStoredUser()?.countyName}?flow=v2` + quoteParam;
    } else {
      goToMg();
    }
  }

  return <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '50vh'}}>
    <Typography className={'medium fs-30 mt-20'} align={'center'}>A licensed Medicare advisor<br /> will be calling you shortly.</Typography>
    <Typography color={'textSecondary'} className={'bold fs-17 mt-20'} align={'center'}>Don't want to wait? Connect now:</Typography>
    <Typography color={'textPrimary'} className={'medium fs-30 mv-10'} align={'center'}>+1 (888) 969-7667</Typography>
    <Typography color={'textSecondary'} className={'fs-17 mb-30 medium'} align={'center'}>TTY Users 711</Typography>
    <Box display={'flex'} justifyContent={'center'}>
      <Button onClick={onClick} sx={{background: '#FF785A!important'}} size={'large'} className={'gtm-step-event'} variant={'contained'}>See Plan Options Online</Button>
    </Box>
  </Box>
}

export default Conversion;
