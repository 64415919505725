import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import { MutationSetCompareYearToYearLockedArgs } from '@coverright/data-access/types/medicare';

export function useSetCompareYearToYearLocked(options?: MutationHookOptions<{setCompareYearToYearLocked: boolean}, MutationSetCompareYearToYearLockedArgs>) {
  return useMutation<{setCompareYearToYearLocked: boolean}, MutationSetCompareYearToYearLockedArgs>(gql(saveVideoMutation), {
    ...options,
  })
}

const saveVideoMutation = `
mutation ($id: UUID!, $isLocked: Boolean!) {
  setCompareYearToYearLocked(id: $id, isLocked: $isLocked)
}
`;
