import {ApolloClient, NormalizedCacheObject} from "@apollo/client";
import React from "react";
import {AgentContext} from "@coverright/shared/contexts";
import {useAgentAssignedToClientLazyQuery} from "@coverright/data-access/types/enrollment";

export const useApplyClientAgent = (apolloEnrollmentClient: ApolloClient<NormalizedCacheObject>, cdnUrl: string, clientId?: string | null) => {
  const {setAgent, applyDefaultAgent} = React.useContext(AgentContext);

  const [getAgent] = useAgentAssignedToClientLazyQuery({
    client: apolloEnrollmentClient,
    onCompleted: data => {
      const agent = data.agentAssignedToClient;
      if (agent) {
        setAgent({
          ...agent,
          picUrl: agent.picUrl ? cdnUrl + agent.picUrl : undefined
        })
      } else {
        applyDefaultAgent()
      }
    },
  })

  React.useEffect(() => {
    if (clientId) {
      getAgent({variables: {clientId}});
    }
  }, [clientId, getAgent])
}
