import {  useLazyQuery, LazyQueryHookOptions } from '@apollo/client';
import {gql} from "@apollo/client";
import { CompareYearToYearOutput, Maybe, QueryCompareYearToYearArgs } from '@coverright/data-access/types/medicare';


export function useMyCompareYearToYear(options?: LazyQueryHookOptions<{myCompareYearToYear: Maybe<CompareYearToYearOutput>}, QueryCompareYearToYearArgs>) {
  return useLazyQuery<{myCompareYearToYear: Maybe<CompareYearToYearOutput>}, QueryCompareYearToYearArgs>(gql(query), {
    fetchPolicy: 'no-cache',
    ...options,
  })
}


const query = `
query {
  myCompareYearToYear {
    clientId
    county
    createdAt
    currentMgPlanKey
    currentMaPlanId
    currentMgNaic
    currentMgPlanName
    currentPdpPlanId
    currentYear
    isLocked
    id
    maIsHidden
    mgIsHidden
    pdpIsHidden
    suggestedMaPlanId
    suggestedMgPlanKey
    suggestedMgNaic
    suggestedMgPlanName
    suggestedPdpPlanId
    updatedAt
    zip
  }
}`;
