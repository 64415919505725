import { Box, MenuItem, Stack, Typography } from '@mui/material';
import * as _ from 'lodash';
import React from 'react';
import { DateInput, SelectInput } from '@coverright/ui/inputs';
import { getAgeByBirthdate, getEffectiveDate, isPersonEligible } from '@coverright/utils';
import moment from 'moment';

export function EffectiveDate() {
  const [age, setAge] = React.useState<number>();
  const [effectiveDate, setEffectiveDate] = React.useState('');
  const [birthdate, setBirthdate] = React.useState('');
  const [useAge, setUseAge] = React.useState(false);

  React.useEffect(() => {
    if (birthdate && moment(birthdate).isValid()) {
      setEffectiveDate(getEffectiveDate(null, birthdate))
      let a = getAgeByBirthdate(birthdate)
      if (!a || a >= 63 && a < 65) {
        a = 65;
      }
      setAge(a)
      setUseAge(false)
    }
  }, [birthdate])

  return <Box sx={{p: 3}}>
    <Stack spacing={2} sx={{margin: 'auto'}}>
      <DateInput
        label={'Birthdate'}
        value={birthdate}
        onChange={e => {
          setBirthdate(e.target.value || '');
        }}
      />
      <Stack direction={'row'} spacing={2}>
        <SelectInput label={'Age at effective date'}
                     data-test={'age'}
                     placeholder={'Select your age'}
                     labelClassName={'fs-12 lh-15'}
                     className={'w-85'}
                     value={age ? (age < 64 ? 64 : age) : 64}
                     onChange={event => {
                       setUseAge(true)
                       setAge(event.target.value as number)
                     }}
        >
          <MenuItem key={64} value={64}>&lt;64</MenuItem>
          {_.fill(Array(50), 2).map((p, i) => <MenuItem key={i} value={i + 65}>{i + 65}</MenuItem>)}
        </SelectInput>
        <Typography>(real age: {getAgeByBirthdate(birthdate)})</Typography>
      </Stack>
      <DateInput
        label={'Effective date'}
        value={effectiveDate}
        onChange={e => {
          setEffectiveDate(e.target.value || '');
        }}
      />
      {birthdate && <Typography>Is Eligible: {_.toString(isPersonEligible(useAge ? age : (birthdate || '1955-01-01'), effectiveDate))}</Typography>}
    </Stack>
  </Box>
}
