import React, {useContext} from 'react';
import { delegate } from '@coverright/utils';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { KeycloakContext } from '@coverright/shared/keycloak';
import { EffectiveDate } from './pages/test/effective-date';
import { LayoutWithNotification } from '@coverright/ui/marketplaces';
import AdminNotification from './components/admin-notification';
import {
  identifyHotjar,
  logEvent,
  useTrackAppointment,
} from '@coverright/shared/analytics';
import * as _ from 'lodash';
import { PageRoutes } from './config/page-routes';
import CompareOffer from './pages/compare-offer/compare-offer';
import PdpCompareOffer from './pages/compare-offer/pdp-compare-offer';
import Conversion from './pages/conversion/Conversion';
import YearToYearCompare from './pages/year-to-year-compare/year-to-year-compare';
import { Box, styled } from '@mui/material';
import { Terms, TrustpilotWidget } from '@coverright/ui/shared';
import {PrivateRoute, useApplyClientAgent} from '@coverright/features';
import { AppBar } from './components/app-bar';
import { EnrollmentInfo } from './pages/enrollment-info';
import {enrollmentClient} from "@coverright/data-access/apollo-clients";
import {environment} from "../environments/environment";
import {QuoteContext} from "@coverright/shared/contexts";

function App() {
  const {clientId} = useContext(QuoteContext);
  const { isAdmin } = useContext(KeycloakContext);
  const { pathname } = useLocation();
  useTrackAppointment(enrollmentClient);
  useApplyClientAgent(enrollmentClient, environment.cdnUrl, clientId);


  React.useEffect(() => {
    delegate(document, 'click', 'button', function (event) {
      logEvent('click', {
        element_title: event.target.textContent,
        element_id: event.target.id,
        element_type: 'button',
      });
    });
  }, []);

  React.useEffect(() => {
    identifyHotjar();
    if (pathname) {
      const pageName = _.startCase(_.last(pathname.split('/')));
      logEvent('page_view', { page_title: pageName });
    }
  }, [pathname]);

  return (
    <LayoutWithNotification
      showNotification={isAdmin}
      NotificationComponent={<AdminNotification />}
    >
      <AppBar />

      <Container>
        <Routes>
          <Route path={'/test/effective-date'} element={<EffectiveDate />} />
          <Route path={'/enrollment-info'} element={<EnrollmentInfo />} />

          <Route path={'/'} element={<PrivateRoute />}>
            <Route path={PageRoutes.compareOffer} element={<CompareOffer />} />
            <Route
              path={PageRoutes.pdpCompareOffer}
              element={<PdpCompareOffer />}
            />
            <Route
              path={PageRoutes.yearToYear}
              element={<YearToYearCompare />}
            />
          </Route>

          <Route path={PageRoutes.conversionMa} element={<Conversion />} />
          <Route path={PageRoutes.conversionMg} element={<Conversion />} />

          <Route
            path={'*'}
            element={<Navigate to={'/application-onboarding'} />}
          />
          <Route
            path={''}
            element={<Navigate to={'/application-onboarding'} />}
          />
        </Routes>
      </Container>

      {[PageRoutes.compareOffer, PageRoutes.pdpCompareOffer].includes(
        pathname as PageRoutes
      ) && (
        <Box
          sx={{ marginLeft: '20px', marginBottom: { xs: '125px', xl: '30px' } }}
        >
          <Box
            sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
            className={'mt-40 mb-10'}
          >
            <a
              href="https://www.bbb.org/us/ny/brooklyn/profile/medicare/coverright-inc-0121-87151389/#sealclick"
              id="bbblink"
              className="rbhzbus"
              target="_blank"
              rel="nofollow"
            >
              <img
                src="https://seal-newyork.bbb.org/logo/rbhzbus/bbb-87151389.png"
                style={{ border: 0 }}
                alt="CoverRight Inc.  BBB Business Review"
              />
            </a>
            <TrustpilotWidget />
          </Box>
          <Terms />
        </Box>
      )}
    </LayoutWithNotification>
  );
}

export default App;

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  [theme.breakpoints.up('md')]: {
    marginTop: '85px',
  },
  backgroundColor: '#F5F7F6',
  flexDirection: 'column',
  padding: '16px 24px',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '100vw',
  },
}));
