import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import { MutationSaveCompareOfferHistoryItemArgs } from '@coverright/data-access/types/enrollment';
import { enrollmentClient } from '@coverright/data-access/apollo-clients';

export function useSaveCompareOfferHistoryItem(options?: MutationHookOptions<{saveCompareOfferHistoryItem: boolean}, MutationSaveCompareOfferHistoryItemArgs>) {
  return useMutation<{saveCompareOfferHistoryItem: boolean}, MutationSaveCompareOfferHistoryItemArgs>(gql(mutation), {
    ...options,
    client: enrollmentClient
  })
}

const mutation = `
mutation ($input: CompareOfferHistoryInput!) {
  saveCompareOfferHistoryItem(input: $input)
}
`;

