import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import { MutationDeleteCompareOfferHistoryItemArgs } from '@coverright/data-access/types/enrollment';
import { enrollmentClient } from '@coverright/data-access/apollo-clients';

export function useDeleteCompareOfferHistoryItem(options?: MutationHookOptions<{deleteCompareOfferHistoryItem: boolean}, MutationDeleteCompareOfferHistoryItemArgs>) {
  return useMutation<{deleteCompareOfferHistoryItem: boolean}, MutationDeleteCompareOfferHistoryItemArgs>(gql(mutation), {
    ...options,
    client: enrollmentClient
  })
}

const mutation = `
mutation ($id: UUID!) {
  deleteCompareOfferHistoryItem(id: $id)
}
`;

