import React from "react";
import {
  FormControlLabel,
  FormControlLabelProps,
  Radio as MuiRadio,
  RadioProps as MuiRadioProps,
  Typography
} from '@mui/material';
import { CheckCircle, Circle } from '@phosphor-icons/react';


type QuizRadioProps = MuiRadioProps & {label: React.ReactNode, labelProps?: Omit<FormControlLabelProps, 'label' | 'control'>};

export const Radio = (props: QuizRadioProps) => {
  const {label, labelProps, ...rest} = props;
  return (
    <FormControlLabel sx={{
                        border: props.checked ? `1px solid #1C434F` : `1px solid #B3B3B3`,
                        backgroundColor:  props.checked ? '#1E95A0' : undefined,
                        padding: '16px',
                        display: 'flex',
                        flex: 1,
                        borderRadius: '8px',
                        marginRight: '0!important',
                        marginLeft: '0!important',
                        alignItems: 'flex-start',
                        ...labelProps?.sx
                      }}
                      control={<MuiRadio
                        sx={{p: '0px'}}
                        disableRipple
                        color="default"
                        checkedIcon={<CheckCircle size={32} color="#ffffff" weight="fill" />}
                        icon={<Circle size={32} color="#1C434F" weight="regular" />}
                        {...rest}
                      />}
                      label={<Typography variant={'body2'}
                                         sx={{color: props.checked ? '#FFF' : '#000', ml: 2}}
                                         className={'medium'}>{label}</Typography>}
    />

  );
}
