import { useCompareOfferHistory, useDeleteCompareOfferHistoryItem } from '@coverright/data-access/enrollment';
import React from 'react';
import { AdminContext, CompareOfferContext } from '@coverright/shared/contexts';
import { Backdrop, Box, Divider, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { environment } from '../../../environments/environment';
import { CompareOfferHistoryOutput } from '@coverright/data-access/types/enrollment';
import { KeycloakContext } from '@coverright/shared/keycloak';
import * as _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';

export const CompareOfferHistoryList = () => {
  const adminContext = React.useContext(AdminContext);
  const {clientId} = React.useContext(CompareOfferContext);
  const [items, setItems] = React.useState<CompareOfferHistoryOutput[]>([]);
  const [getHistory, data] = useCompareOfferHistory();
  const [deleteItem, deleteData] = useDeleteCompareOfferHistoryItem();
  const [url, setUrl] = React.useState<string>();
  const [date, setDate] = React.useState<string>();
  const [backdropOpen, setBackdropOpen] = React.useState(false);

  React.useEffect(() => {
    if (clientId) {
      getHistory({variables: {clientId}})
    }
  }, [clientId])

  React.useEffect(() => {
    if (data.data?.compareOfferHistory) {
      setItems(data.data?.compareOfferHistory)
    }
  }, [data])

  const download = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault()
    if (url) {
      const link = document.createElement("a");
      link.download = [adminContext?.clientView.firstName, adminContext?.clientView.lastName].join('_') + `_compare_offer_${moment(date).format('YYYY_MM_DD')}.png`;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  return <>
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }}
      open={backdropOpen}
      onClick={() => setBackdropOpen(false)}
    >
      <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 1, position: 'fixed', pr: '10px', top: 67, backgroundColor: 'rgba(28,67,79,0.92)', width: '100%'}}>
        <IconButton onClick={download}>
          <DownloadIcon sx={{color: 'white'}} />
        </IconButton>
        <IconButton onClick={() => {
          setBackdropOpen(false);
          setUrl(undefined)
          setDate(undefined)
        }}>
          <CloseIcon sx={{color: 'white'}} />
        </IconButton>
      </Box>
      <Box sx={{width: '80%', mt: '100px'}}>
        <Box component={'img'} src={url} sx={{width: '100%', height: 'auto', display: 'block'}}/>
      </Box>
    </Backdrop>
    <Stack sx={{pt: 10, px: 1}}>
      {_.orderBy(items, 'createdAt', 'desc').map((item, i, array) => <div>
        <Preview item={item}
                 key={item.id}
                 loading={data.loading || deleteData.loading}
                 onDeleteClick={() => {
                   deleteItem({variables: {id: item.id}}).finally(data.refetch)
                 }}
                 onClick={img => {
                   setUrl(img)
                   setBackdropOpen(true)
                 }} />
        {i < array.length - 1 && <Divider sx={{mt: 2}} />}
      </div>)}
    </Stack>
  </>
}

const Preview = ({item, onClick, onDeleteClick, loading}: {item: CompareOfferHistoryOutput, loading: boolean, onClick: (url: string) => void, onDeleteClick: () => void}) => {
  const {keycloak} = React.useContext(KeycloakContext);
  const [url, setUrl] = React.useState<string>();

  React.useEffect(() => {
    if (item) {
      fetch(
        environment.clientFilesApiUrl + item.screenshotLink,
        {
          method: 'GET',
          headers: {
            authorization: 'Bearer ' + keycloak?.token
          }
        }
      )
        .then(response => response.blob())
        .then((data: any) => {
        if (data) {
          setUrl(URL.createObjectURL(data))
        }
      })
    }
  }, [item])

  return <Box sx={{width: '200px'}}>
    {(!url || loading) && <div>
      <Skeleton className={'mb-5'} width={200} height={30} variant={'text'} />
      <Skeleton variant={'rectangular'} width={200} height={150} />
    </div>}
    {(url && !loading) && <>
      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <Typography className={'fs-12 bold'}>{moment(item.createdAt).format('L LTS')}</Typography>
        <IconButton onClick={onDeleteClick}>
          <CloseIcon fontSize={'small'} />
        </IconButton>
      </Box>
      <Box onClick={() => url && onClick(url)} component={'img'} src={url} sx={{width: '200px', height: 'auto', display: 'block', cursor: 'pointer'}} />
    </>}
  </Box>
}
