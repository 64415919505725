import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import { MutationSendY2YEmailArgs } from '@coverright/data-access/types/enrollment';
import { uploadClient } from '@coverright/data-access/apollo-clients';

export function useSendY2YEmail(options?: MutationHookOptions<{sendY2YEmail: boolean}, MutationSendY2YEmailArgs>) {
  return useMutation<{sendY2YEmail: boolean}, MutationSendY2YEmailArgs>(gql(mutation), {
    client: uploadClient,
    ...options
  })
}

const mutation = `
mutation ($clientId: UUID!, $image: Upload!) {
  sendY2YEmail(image: $image, clientId: $clientId)
}
`;
