import React from 'react';
import { Tooltip } from '@coverright/ui/inputs';
import { Box } from '@mui/material';
import { QuoteContext } from '@coverright/shared/contexts';
import { getQuoteId } from '@coverright/utils';
import { useLogEvent } from '@coverright/shared/analytics';
import StyledRadio from './styled-radio';

const SituationSelect = (props: {onChange?: (value?: Situation) => void, value?: Situation}) => {
  const {save, customAnswers} = React.useContext(QuoteContext);
  const [value, setValue] = React.useState<Situation | undefined>(props.value);
  const logEvent = useLogEvent();

  React.useEffect(() => {
    setValue(props.value)
  }, [props.value]);

  React.useEffect(() => {
    setSituation(value, customAnswers || undefined, save, logEvent);
    if (props.onChange) {
      props.onChange(value);
    }
  }, [value, customAnswers])


  return <Box sx={{display: 'flex', alignItems: 'center', flexDirection: {xs: 'column', md: 'row'}, gap: '12px', marginBottom: '70px'}}>
    <Tooltip title={'Select this if you are enrolling into Medicare for the first time.  This includes if you are leaving employer-provided coverage for the first time after becoming eligible.'} arrow>
      <div data-test='NEW_TO_MEDICARE'>
        <StyledRadio checked={value === Situation.NEW_TO_MEDICARE}
                     onClick={() => setValue(0)}
                     label={situationAnswers[0]}
                     height={240}
                     imgUrl={'/assets/img/MedicareIcon.svg'} />
      </div>
    </Tooltip>
    <Tooltip title={'Select this if you are shopping for a better coverage or rates.'} arrow>
      <div data-test='BETTER_COVERAGE'>
        <StyledRadio checked={value === Situation.BETTER_COVERAGE}
                     onClick={() => setValue(1)}
                     label={situationAnswers[1]}
                     height={240}
                     imgUrl={'/assets/img/Shopping.svg'} />
      </div>
    </Tooltip>
    <Tooltip placement={'top'} title={'Select this if you need to replace your current Medicare plan because you are moving residence or have any other special event which requires you to change your plan by a specific date. '} arrow>
      <div data-test='REPLACE_CURRENT_PLAN'>
        <StyledRadio checked={value === Situation.REPLACE_CURRENT_PLAN}
                     onClick={() => setValue(2)}
                     label={situationAnswers[2]}
                     height={240}
                     imgUrl={'/assets/img/Replace.svg'} />
      </div>
    </Tooltip>
  </Box>
}

export default SituationSelect;

export const setSituation = (value: Situation | undefined, customAnswers = '{}', save: any, logEvent: any) => {

  if (typeof value !== 'undefined') {
    if (getQuoteId()) {
      save({
        variables: {
          data: {
            customAnswers: JSON.stringify({
              ...JSON.parse(customAnswers), situation: {
                value,
                answerText: situationAnswers[value]
              }
            }),
            id: getQuoteId(),
          }
        },
      })
    }
    if ((window as any).track) {
      let label: string;
      switch(value) {
        case Situation.BETTER_COVERAGE: label = 'better-coverage'; break;
        case Situation.NEW_TO_MEDICARE: label = 'new-to-medicare'; break;
        case Situation.REPLACE_CURRENT_PLAN: label = 'replace-plan'; break;
      }
      logEvent('Persona selected', {'selected_option': label})
    }
  }
}


export enum Situation {
  NEW_TO_MEDICARE,
  BETTER_COVERAGE,
  REPLACE_CURRENT_PLAN,
}

export const situationAnswers = [
  'I’m new to Medicare',
  'I’m shopping for better coverage',
  'I need to replace my current plan',
]
