
import { enrollmentClient } from '@coverright/data-access/apollo-clients';
import { ClientViewOutput } from '@coverright/data-access/types/enrollment';
import { useSubscription, gql } from '@apollo/client';

export function useClientView(id: string) {
  const {data} = useSubscription<{clientCard: ClientViewOutput}>(gql(query), {
    client: enrollmentClient,
    variables: {
      id
    }
  });

  return data?.clientCard;
}

const clientFields = `
    id
    agentMedicareQuote {
      agentOffer
      cId
      countyName
      createdAt
      pin
      quoteId
      state
      userId
      zip
    }
    agentMedigapQuote {
      cId
      createdAt
      id
      updatedAt
      userId
    }
    medigapQuote {
      id
      createdAt
    }
    pdpQuote {
      id
    }
    countyName
    customAnswers
    email
    firstName
    lastName
    gender
    tobacco
    phoneNumber
    pin
    profileId
    quoteId
    status
    birthDate
    zip
    scopeOfAppointmentStatus
`;

const query = `
subscription($id: UUID!) {
  clientCard (data:{
    clientId: $id
  }) {
    ${clientFields}
  }
}
`;

